/// <reference types="matrixrequirements-type-declarations" />
import { app, ControlState, globalMatrix, matrixSession } from "../../../globals";
import { FieldHandlerFactory, IFileUploadProgress } from "../../businesslogic/index";
import { Tasks } from "../../businesslogic/index";
import { HTMLCleaner } from "../../matrixlib/index";
import { ItemControl } from "../Components/ItemForm";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { FileManagerImpl } from "./fileManager";
import { RichText2 } from "./richText2";
import { ml } from "./../../matrixlib";
import { IBlockingProgressUITask, IUploadedFileInfo } from "../../matrixlib/MatrixLibInterfaces";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { RichtextFieldHandler } from "../../businesslogic/FieldHandlers/RichtextFieldHandler";
import { IFieldParameterCommon } from "../../../ProjectSettings";

export type { IRichTextParams, IRichTextControlOptions };
export { RichTextImpl };

interface IRichTextParams extends IFieldParameterCommon {
    showSmartText?: boolean;
    autoEdit?: boolean;
    height?: number;
    docMode?: boolean;
    tableMode?: boolean;
    readonly?: boolean;
    wiki?: boolean;
    tiny?: boolean; // force usage of tiny
    noConvertTiny?: boolean; // do not show the convert button to allow old summernote conversion to tiny
    requiresContent?: boolean;
    printMode?: boolean; // add's special functions and menus for making print templates
    width?: string; // allows to overwrite the default width of 18cm
    widthViewer?: string; // allows to overwrite the default width (above) used when viewing text
    initialContent?: string; // allows to set the initial content of text boxes
    visibleOption?: string; // if set, the richtext box is only shown in the specified category
    autoFocus?: boolean; // if set, the richtext box is focused when shown
}

interface IRichTextControlOptions extends IBaseControlOptions {
    parameter?: IRichTextParams;
    disableTinyMce?: boolean;
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
$.fn.richText = function (this: JQuery, options: IRichTextControlOptions, form?: ItemControl) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_richtext,
            options,
        );
        options.fieldHandler.initData(JSON.stringify(options.fieldValue));
    }
    let baseControl = RichTextImpl.useTiny(options)
        ? new RichText2(this, options.fieldHandler as RichtextFieldHandler)
        : new RichTextImpl(this, options.fieldHandler as RichtextFieldHandler);
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options, form);
    return this;
};

class RichTextImpl extends BaseControl<RichtextFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: IRichTextControlOptions;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lastClient: { x: number; y: number };
    private editStart: boolean = false;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private data: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lastScroll: number;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private hiddenPasteBuffer: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _editor: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private editable: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lastValueChanged: number;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private form: ItemControl;
    private resizable = false;
    private heightDelta: number = 0;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private failedImages: string[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private recCall: boolean;

    static editorInstanceCount: number = 0;

    constructor(control: JQuery, fieldHandler: RichtextFieldHandler) {
        super(control, fieldHandler);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    init(options: IRichTextControlOptions, form?: ItemControl) {
        let that = this;

        this.failedImages = [];

        let defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dumy text (for form design...)
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            valueChanged: function () {}, // callback to call if value changes
            canEdit: false, // whether data can be edited
            parameter: {
                height: 250, // height in pixel
                readonly: false, // can be set to overwrite the default readonly status
                showSmartText: true, // allow users to insert smart text
                tableMode: false, // in table mode no smart text can be added to table cells also from 1.11 table+figure captions are supported (in docs)
                docMode: false, // in doc mode headers will show up as heading in table of content of docs... also from 1.11 table+figure captions are supported (in docs)
                autoEdit: false, // whether the control should be directly in edit mode when shown
                wiki: true, // by default allow to enter list and tables in wiki style
            },
        };

        if (options.parameter && (!options.parameter.height || options.parameter.height < 0)) {
            this.resizable = true;
            options.parameter.height = options.parameter.height ? -options.parameter.height : 250; // default
            if (globalMatrix.projectStorage.getItem("eh" + options.fieldId)) {
                options.parameter.height = parseInt(globalMatrix.projectStorage.getItem("eh" + options.fieldId));
            }
            if (globalMatrix.projectStorage.getItem("eh" + options.fieldId + "_" + options.id)) {
                options.parameter.height = parseInt(
                    globalMatrix.projectStorage.getItem("eh" + options.fieldId + "_" + options.id),
                );
            }
        }
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.form = form;
        this.lastClient = { x: 0, y: 0 }; // last client position as workaround for chrome bug
        RichTextImpl.editorInstanceCount++;

        this.hiddenPasteBuffer = $(
            "<div tabindex='-1' contenteditable='true' style='position: absolute; left: -100000px;'>",
        );
        this._root.attr("id", "rtctrl" + RichTextImpl.editorInstanceCount);
        this.data = typeof this.settings.fieldValue === "undefined" ? "" : this.settings.fieldValue;
        this.fieldHandler.initData(this.data);

        this._root.addClass("richText");
        if (
            this.settings.controlState === ControlState.Tooltip ||
            this.settings.controlState === ControlState.Print ||
            this.settings.controlState === ControlState.HistoryView ||
            this.settings.controlState === ControlState.Zen
        ) {
            this._root.append(super.createHelp(this.settings));

            let css = this.settings.controlState === ControlState.Print ? "class='printBox'" : "baseControl";

            let text =
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                this.settings.controlState == ControlState.HistoryView || this.settings.controlState == ControlState.Zen
                    ? this.data
                    : ml.SmartText.replaceTextFragments(this.data);

            this._root.append("<div class='" + css + "'>" + text + "</div>");
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (this.settings.controlState != ControlState.HistoryView) {
                ml.SmartText.showTooltips(this._root);
                this._root.highlightReferences();
            }
            return;
        }
        let toolbar = [
            ["style", ["style"]],
            ["font", ["bold", "italic", "underline", "clear"]],
            ["color", ["color"]],
            ["para", ["ul", "ol", "paragraph"]],
            ["height", ["height"]],
            ["table", ["table"]],
            ["insert", ["link", "picture", "hr"]],
            ["view", ["fullscreen", "codeview"]],
        ];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.settings.parameter.showSmartText) {
            toolbar.splice(6, 0, ["group", ["smarttext"]]);
        }
        this._root.append(super.createHelp(this.settings));
        let ctrlContainer = $("<div>").addClass("baseControl");
        this._root.append(ctrlContainer);

        this._editor = $("<div>");

        ctrlContainer.append(this.hiddenPasteBuffer);
        ctrlContainer.append(this._editor);

        (<Summernote.editor>this._editor).summernote({
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            height: this.settings.parameter.height + "px",
            onkeyup: function (e: KeyboardEvent) {
                if (!that.editStart) {
                    that.showMenu();
                }
                that.triggerValueChange();
            },
            codemirror: {
                // codemirror options
                theme: "3024-day",
                lineNumbers: true,
                mode: "htmlmixed",
            },
            onblur: function (e: Event) {
                let selection = window.getSelection();
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (selection.rangeCount > 0) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let range = selection.getRangeAt(0);
                    that.editable.data("lastRange", range);
                }
            },
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            onfocus: function (e: KeyboardEvent): any {
                if (e.ctrlKey) {
                    return false;
                }
            },
            onpaste: function (e: JQueryEventObject) {
                let targetElement = $(e.delegateTarget);
                let selection = window.getSelection();
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let range = selection.getRangeAt(0);
                window.setTimeout(function () {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.processpaste(targetElement, selection, range);
                }, 1);
                // paste into a hidden editable to clean it up later
                that.hiddenPasteBuffer.html("");
                that.hiddenPasteBuffer.focus();

                return;
            },
            onChange: function () {
                that.triggerValueChange();
            },
            oninit: function () {
                // Add close button
                let editCloseBtn = $(
                    '<button type="button" class="btn btn-default btn-sm btn-small" title="Close Editor" data-event="" tabindex="-1"><i class="fal fa-check"></i></button>',
                );
                let editCloseBtnGroup = $('<div class="note-file btn-group">');
                editCloseBtnGroup.append(editCloseBtn);
                editCloseBtnGroup.appendTo($(".note-toolbar", that._root));
                // Button tooltips
                editCloseBtn.tooltip({ container: "body", placement: "bottom" });
                // Button events
                editCloseBtn.click(function (event) {
                    if (!that.editStart) {
                        // not in edit mode (this should not really happen)
                        return;
                    }
                    that.data = that._editor.code();
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (that.settings.parameter.wiki) {
                        that.data = new HTMLCleaner(that.data, false).replaceWiki();
                    }

                    that._editor.code(ml.SmartText.replaceTextFragments(that.data, true));
                    that.editStart = false;
                    ml.SmartText.showTooltips(that._root);
                    that.hideMenu();
                });

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (matrixSession.getUISettings().tinyUpgradeOption && !that.settings.parameter.noConvertTiny) {
                    // add button to change editor to tiny
                    let upgradeBtn = $(
                        '<button type="button" class="btn btn btn-link" data-event="" tabindex="-1">use new editor</button>',
                    );
                    let upgradeBtnGroup = $('<div class="note-file btn-group">');
                    upgradeBtnGroup.append(upgradeBtn);
                    upgradeBtnGroup.appendTo($(".note-toolbar", that._root));
                    // Button tooltips
                    upgradeBtn.tooltip({ container: "body", placement: "bottom" });
                    // Button events
                    upgradeBtn.click(function (event) {
                        if (that.editable.children().length) {
                            that.editable.children().first().addClass("2.2");
                        } else {
                            that.editable.html("<p class='2.2'> " + that.editable.html() + "</p>");
                        }
                        that.triggerValueChange();
                        ml.UI.showSuccess("Save to switch");
                    });
                }
            },
            onImageUpload: function (files: FileList, editor: Summernote.editor, $editable: JQuery) {
                that.uploadAndInsertImages(files, editor, $editable);
            },
            disableDragAndDrop: true,
            toolbar: toolbar,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            docMode: this.settings.parameter.docMode,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            tableMode: this.settings.parameter.tableMode,
        });

        let a = <number>ControlState.Print === <number>this.settings.controlState;

        // get value of field before changes and before manipulation
        this._editor.code(this.data);
        let rt = this._editor.code();
        this._root.data("original", rt);
        this._root.data("new", rt);

        // add smart text to code
        this._editor.code(
            ml.SmartText.replaceTextFragments(
                this.data,
                !(
                    this.settings.controlState === <number>ControlState.Print ||
                    this.settings.controlState === <number>ControlState.Tooltip
                ),
            ),
        );
        ml.SmartText.showTooltips(this._root);

        this.editable = $(".note-editable", this._root);

        // do this cosmetic stuff and text highlighting after real content was saved, otherwise save will be required
        if (!this.settings.canEdit) {
            this.editable.prop("contenteditable", false);
            $(".btn", this._root).prop("disabled", true);
            $(".note-toolbar", this._root).hide();
            this._root.highlightReferences();
        } else {
            let badSrc = $("<div class='note-toolbar-imagesrc'>").html(
                "There are some image links to external file servers. ",
            );
            badSrc.append(
                "(<a style='color:white;text-decoration:underline' href='https://urlshort.matrixreq.com/d25/manual/externallinks' target='_blank'>help!</a>)",
            );
            let which = $(
                "<span> (<span style='color:white;text-decoration:underline;cursor:pointer' >which?</span>)</span>",
            );
            which.click(() => this.showBadImages());
            badSrc.append(which);
            badSrc.insertAfter($(".note-toolbar", this._root));
            $("<div class='note-toolbar-dummy'></div>").insertAfter($(".note-toolbar", this._root));
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (this.settings.parameter.autoEdit) {
                this.hideMenu();
                this.showMenu();

                window.setTimeout(function () {
                    // set cursor to end of text
                    let el = that.editable[0];
                    el.focus();
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
                        let range = document.createRange();
                        range.selectNodeContents(el);
                        range.collapse(false);
                        let sel = window.getSelection();
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        sel.removeAllRanges();
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        sel.addRange(range);
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                    } else if (typeof (<Summernote.HTMLElementExt>document.body).createTextRange != "undefined") {
                        let textRange = (<Summernote.HTMLElementExt>document.body).createTextRange();
                        (<Summernote.HTMLElementExt>textRange).moveToElementText(el);
                        (<Summernote.HTMLElementExt>textRange).collapse(false);
                        (<Summernote.HTMLElementExt>textRange).select();
                    }
                }, 200);
            } else {
                this.hideMenu();
            }
        }
        this.addVerticalResizer();
        if (this.settings.canEdit) {
            this.editable[0].addEventListener("dragover", (event) => this.onDragOver(event));
            this.editable[0].addEventListener("drop", (event) => this.onDrop(event));
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.heightDelta = this.settings.parameter.height - this.editable.height();

        // mark all the bad images - if there are any
        this.markBadImages();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getValueAsync() {
        if (!this.editStart) {
            return this.data;
        }

        // make sure no changes are pending, update _root.data("new")
        clearTimeout(this.lastValueChanged);
        this.valueChanged(true);

        // close modals in case there are any open
        this._root.find(".modal:visible").modal("hide");

        // create nice html from _root.data("new")
        let di = document.implementation;
        let hd = di.createHTMLDocument("dummy title for ie");
        let xd = di.createDocument("http://www.w3.org/1999/xhtml", "html", null);
        // the formatting is added by the editor
        let theHTML = this._root.data("new");
        let find = "color: rgb(51, 51, 51);";
        let re = new RegExp(find.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1"), "g");
        theHTML = theHTML.replace(re, "");
        find = "font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;";
        re = new RegExp(find.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1"), "g");
        theHTML = theHTML.replace(re, "");
        find = "font-family: Helvetica, Arial, sans-serif;";
        re = new RegExp(find.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1"), "g");
        theHTML = theHTML.replace(re, "");
        // make nice html out of it
        hd.body.innerHTML = "<div>" + theHTML + "</div>";
        let html_body = hd.body.firstElementChild;
        let xb = xd.createElement("body");
        xd.documentElement.appendChild(xb);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let xhtml_body = xd.importNode(html_body, true); //or xd.adoptNode(img). Now img is a xhtml element
        xb.appendChild(xhtml_body);
        let html = xb.innerHTML;
        let xhtmltag = html.indexOf('<div xmlns="http://www.w3.org/1999/xhtml">');
        let lastdivtag = html.lastIndexOf("</div>");
        if (xhtmltag === 0 && lastdivtag === html.length - 6) {
            html = html.substr(42, html.length - 42 - 6); // 42??? is length of <div xmlns="http://www.w3.org/1999/xhtml">
        }
        return html;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setValue(newVal: string) {
        let that = this;
        this.data = newVal;
        that.fieldHandler.setData(newVal);
        this._root.data("new", newVal);
        this._editor.code(newVal);
        this.importImages(true).done(function () {
            that.markBadImages();
        });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async hasChangedAsync() {
        if (this._root.data("original") === undefined) {
            // the element has been destroyed after a save with ctrl-s
            return false;
        }
        // make sure no changes are pending
        clearTimeout(this.lastValueChanged);
        this.valueChanged(true);
        return this._root.data("original") !== this._root.data("new");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getText() {}
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getValueRaw() {}
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {
        if (this._editor) {
            this.editable[0].removeEventListener("dragover", this.onDragOver);
            this.editable[0].removeEventListener("drop", this.onDrop);

            (<Summernote.editor>this._editor).destroy();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this._editor = null;
        }
        this.editStart = false;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resizeItem() {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static useTiny(ctrlParameter: IRichTextControlOptions) {
        // check global per project
        if (
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            globalMatrix.ItemConfig == undefined ||
            globalMatrix.ItemConfig.getExtrasConfig().defaultToNewEditor ||
            matrixSession.getUISettings().tinyAsDefault
        ) {
            return true;
        }

        // check per field setting
        if (ctrlParameter.parameter && ctrlParameter.parameter.tiny) {
            return true;
        }
        // check manual upgrade action
        if (matrixSession.getUISettings().tinyUpgradeOption) {
            if (!ctrlParameter.fieldValue) {
                return true;
            } else {
                try {
                    if ($(ctrlParameter.fieldValue).first().hasClass("2.2")) {
                        return true;
                    }
                } catch (ex) {
                    // will get here if the field value is plain text (so it cannot be a converted richtext)
                }
            }
        }
        return false;
    }
    // private functions

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private triggerValueChange() {
        let that = this;
        clearTimeout(that.lastValueChanged);
        that.lastValueChanged = window.setTimeout(function () {
            that.importImages(false).done(function () {
                that.markBadImages();
                that.valueChanged();
            });
        }, 50);
    }

    private valueChanged(noCallback?: boolean): void {
        if (this.recCall) {
            return;
        }

        if (this._editor && this.editStart) {
            // currently in edit mode
            let newdata = this._editor.code();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (this.settings.parameter.wiki && noCallback) {
                // no callback means "it is not just while typing...""
                newdata = new HTMLCleaner(newdata, false).replaceWiki();
            }

            this.fieldHandler.initData(newdata);
            this._root.data("new", newdata);
        }
        if (this.settings.valueChanged && !noCallback) {
            this.recCall = true;
            this.settings.valueChanged.apply(null);
            this.recCall = false;
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showMenu(cursorX?: number, cursorY?: number, iePos?: number) {
        let that = this;

        if (this.editStart) {
            // already in edit mode
            return;
        }
        this.editStart = true;
        // copy the original text inside - now in edit mode
        this._editor.code(this.data);
        let rt = this._editor.code();
        this.fieldHandler.initData(rt);
        this._root.data("new", rt);

        let posBefore = this.editable.offset();

        $(".note-toolbar", this._root).show();
        if (this.editable.parent().hasClass("fullscreen")) {
            this.editable.height(this.editable.height() - $(".note-toolbar", this._root).height());
        }
        $(".note-toolbar-dummy", this._root).hide();

        if (cursorX) {
            window.setTimeout(function () {
                // timeout is for IE...
                // simulate mouse click at the original click position...
                // add the differences of position due to toolbar
                let posAfter = that.editable.offset();
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                let e = <Summernote.editor>(<any>$).summernote.eventHandler.getEditor();
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                e.setCursorXY(cursorX, posAfter.top - posBefore.top + cursorY, iePos);
            }, 100);
        }

        // disable open link directly in other window
        $(".note-editable a", this._root).off("mousedown");
        // hide tooltips floating around MATRIX-2774
        $(".smarttext-tooltip").remove();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private markBadImages() {
        let that = this;
        let imgs = $("img", this.editable);
        let warn = false;
        $.each(imgs, function (iidx: number, img: HTMLImageElement) {
            if (img.src.indexOf(globalMatrix.matrixBaseUrl) !== 0 && img.src.indexOf("data:image") !== 0) {
                warn = true;
                if (that.failedImages.indexOf(img.src) === -1) {
                    that.failedImages.push(img.src);
                }
                $(img).addClass("failedImage");
            }
        });

        if (warn) {
            $(".note-toolbar-imagesrc", this._root).show();
        } else {
            $(".note-toolbar-imagesrc", this._root).hide();
        }
    }

    // make sure (new) images are hosted by matrix req
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private importImages(fromPasteBuffer: boolean): JQueryDeferred<{}> {
        let that = this;

        let needsImport: HTMLImageElement[] = [];
        let imgs = fromPasteBuffer ? $("img", this.hiddenPasteBuffer) : $("img", this.editable);
        $.each(imgs, function (iidx: number, img: HTMLImageElement) {
            if (
                img.src.indexOf(globalMatrix.matrixBaseUrl) !== 0 &&
                img.src.indexOf("data:image") !== 0 &&
                that.failedImages.indexOf(img.src) === -1
            ) {
                needsImport.push(img);
            }
        });

        if (needsImport.length > 0) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return this.importImagesRec(needsImport, 0);
        }

        let res = $.Deferred();
        res.resolve();
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // ask server to upload external images (client cannot do ...)
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private importImagesRec(needsImport: HTMLImageElement[], next: number) {
        let that = this;
        let res = $.Deferred();

        if (next === needsImport.length) {
            // we are done with recursion
            ml.UI.BlockingProgress.SetProgress(0, 100);
            res.resolve();
            return res;
        }

        let img = needsImport[next];
        let src = img.src;
        next = next + 1;

        if (this.failedImages.indexOf(src) !== -1) {
            // we can sckip this one
            this.importImagesRec(needsImport, next).always(function () {
                res.resolve();
            });
            return res;
        }

        // we need to try to import
        let tasks: IBlockingProgressUITask[] = [];
        tasks.push({ name: src });
        ml.UI.BlockingProgress.Init(tasks);

        app.fetchFileAsync(src, function (p: IFileUploadProgress) {
            let done = p.position || p.loaded;
            let total = p.totalSize || p.total;

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ml.UI.BlockingProgress.SetProgress(0, (99 * done) / total);
        })
            .done(function (result) {
                img.src = `${globalMatrix.matrixBaseUrl}/rest/1/${matrixSession.getProject()}/file/${
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    result.fileId
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                }?key=${result.key}`;
            })
            .fail(function (error) {
                ml.UI.BlockingProgress.SetProgressError(0, `Our servers cannot fetch the image from ${src}`);
                ml.UI.showError("Our servers cannot fetch the image", `Maybe there is some authentication required?`);
            })
            .always(function () {
                that.importImagesRec(needsImport, next).always(function () {
                    res.resolve();
                });
            });
        return res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showBadImages() {
        let html =
            "The following images cannot be retrieved from our server. So you will not see them in documents. You can try to download and import them from here:<br><br><ul style='text-align: left;'>";
        $.each(this.failedImages, function (idx, img) {
            let parts = img.split("/");
            let name = img.length < 70 ? img : img.substring(0, 40) + "....." + img.substr(img.length - 25);
            html += `<li><a download="${parts[parts.length - 1]}" href="${img}" title="${
                parts[parts.length - 1]
            }">${name}<a></li>`;
        });
        html += "</ul>";
        ml.UI.showAck(-2, html, "Try downloading these images");
    }

    private hideMenu(): void {
        let that = this;
        if (this.editable.parent().hasClass("fullscreen")) {
            this.editable.height($(".note-toolbar", this._root).height() + this.editable.height());
        }
        $(".note-toolbar", this._root).hide();
        $(".note-toolbar-dummy", this._root).show();

        this._root.highlightReferences();

        $(".note-editable a:not(.highLink)", this._root).mousedown(function (e: JQueryMouseEventObject) {
            window.open((<HTMLAnchorElement>e.delegateTarget).href, "_blank");
            if (e.preventDefault) {
                e.preventDefault();
            }
            if (e.stopImmediatePropagation) {
                e.stopImmediatePropagation();
            }
        });

        this.editable.mouseup(function (event) {
            if (!that.editStart && (!that.lastScroll || new Date().getTime() - that.lastScroll > 2000)) {
                // IE cannot handle set caret from click position
                let ieSel = document.getSelection();
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let iePos = ieSel.anchorOffset;

                that.showMenu(event.clientX, event.clientY, iePos);
            }
        });
        this.editable.scroll(function () {
            that.lastScroll = new Date().getTime();
        });
    }

    private uploadAndInsertImages(files: FileList, editor: Summernote.editor, $editable: JQuery): void {
        ml.File.UploadFilesAsync(files)
            .done(function (uploaded) {
                $.each(uploaded, function (idx, file) {
                    editor.insertImage(
                        $editable,
                        globalMatrix.matrixRestUrl + "/" + matrixSession.getProject() + "/file/" + file.fileId,
                    );
                });
            })
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            .fail(function () {});
    }

    // level 0: only dangerous stuff
    // level 1: basic code clean up (it should look the same)
    // level 2: ensure the stuff is nice clean html which can be printed and foramtted
    private cleanPastedHTML(input: string, cleaningLevel: HTMLCleaner.CleanLevel): string {
        // 0 remove func char, which sometimes appears when pasting stuff from word...
        // MATRIX-936 weird characters kill xml / doc generation
        // what is weird though? see 2.5 http://www.ietf.org/rfc/rfc4627.txt
        // put in spaces instead of nothing (otherwise some ascii code 10 (linefeed)
        // which looks like a space or tab?) will be eaten away and two words are glued together

        let hc = new HTMLCleaner(input, false);
        return hc.getClean(cleaningLevel);
    }

    private processpaste(targetElement: JQuery, selection: Selection, range: Range): void {
        let that = this;

        // once done with paste, deal with images
        this.importImages(true).done(function () {
            let newHTML = that.hiddenPasteBuffer.html();

            let pasteRaw = that.cleanPastedHTML(newHTML, HTMLCleaner.CleanLevel.Basic);
            let pasteClean = that.cleanPastedHTML(pasteRaw, HTMLCleaner.CleanLevel.Strict);

            // insert pasted data
            (<Summernote.editor>that._editor).insertHTML(
                that.editable,
                targetElement,
                selection,
                range,
                pasteClean,
                pasteRaw,
            );
        });
    }

    private ddUploadFiles(files: FileList, event: Event): void {
        let that = this;

        ml.File.UploadFilesAsync(files)
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .done(function (uploads: IUploadedFileInfo[]) {
                let ud = $("<div>");
                let ud_used = false;

                // normal drag and drop into a rich text field
                $.each(uploads, function (fi: number, u: IUploadedFileInfo) {
                    if (/\.(gif|jpg|jpeg|tiff|png)$/i.test(u.fileName.toLowerCase())) {
                        (<Summernote.editor>that._editor).insertImage(
                            globalMatrix.matrixRestUrl + "/" + matrixSession.getProject() + "/file/" + u.fileId,
                            u.fileName,
                            event,
                            that.lastClient,
                        );
                    } else if (uploads.length === 1 && /\.(docx)$/i.test(u.fileName.toLowerCase())) {
                        ml.UI.showConfirm(
                            9,
                            { title: "What to do with the word document?", ok: "Attach file", nok: "Convert to text" },
                            function () {
                                let link = $("<a class='highLink'>")
                                    .attr(
                                        "href",
                                        globalMatrix.matrixRestUrl +
                                            "/" +
                                            matrixSession.getProject() +
                                            "/file/" +
                                            u.fileId,
                                    )
                                    .attr("target", "_blank")
                                    .html(u.fileName);
                                ud.append(link);
                                ud.append($("<span>").html(fi === uploads.length - 1 ? " " : ", "));
                                (<Summernote.editor>that._editor).insert(ud[0], event, that.lastClient);
                            },
                            function () {
                                ml.UI.confirmSpinningWait("converting document ...");

                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                app.convertDocAsync(Number(u.fileId.split("?")[0]))
                                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                                    // eslint-disable-next-line
                                    .done(function (html: any) {
                                        ud.html(
                                            new HTMLCleaner(html.html, false).getClean(HTMLCleaner.CleanLevel.Strict),
                                        );
                                        (<Summernote.editor>that._editor).insert(ud[0], event, that.lastClient);
                                    })
                                    .always(function () {
                                        ml.UI.closeConfirmSpinningWait();
                                    });
                            },
                        );
                    } else {
                        ud_used = true;
                        let link = $("<a class='highLink'>")
                            .attr(
                                "href",
                                globalMatrix.matrixRestUrl + "/" + matrixSession.getProject() + "/file/" + u.fileId,
                            )
                            .attr("target", "_blank")
                            .html(u.fileName);
                        ud.append(link);
                        ud.append($("<span>").html(fi === uploads.length - 1 ? " " : ", "));
                    }
                });
                if (ud_used) {
                    (<Summernote.editor>that._editor).insert(ud[0], event, that.lastClient);
                }
                if (that.form) {
                    let fields = that.form.getControls("fileManager");
                    $.each(fields, function (idx, fileManager) {
                        if ((<FileManagerImpl>fileManager.getController()).populateFromRichtext()) {
                            (<FileManagerImpl>fileManager.getController()).addLinks(uploads);
                        }
                    });
                }
            })
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            .fail(function (uploads) {});
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private ddCreateLink(display: string, ticketUrl: string, event: Event) {
        display = display ? display : ticketUrl;
        // add ticket link to UI
        let ud = $("<div>");
        ud.append(display);
        let text = $("<div>").html(ud.text());
        (<Summernote.editor>this._editor).insert(text, event, this.lastClient);

        // create issue link
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        Tasks.createTaskFromUrl(this.settings.item.id, ticketUrl);
    }

    private ddUploadHTML(html: JQuery, event: Event): void {
        // here we should send the whole thing to the server
        // the server should
        // a) upload and replace the embedded images
        // b) simplify the html (though how much
        (<Summernote.editor>this._editor).insert(html[0], event, this.lastClient);
    }

    private onDragOver(e: MouseEvent): void {
        // MATRIX-1523 - Drag and drop does not work in chrome 53.* on dual screen with screen scaling
        this.lastClient.x = e.clientX;
        this.lastClient.y = e.clientY;

        this.editable.focus();
    }
    private onDrop(e: DragEvent): boolean {
        if (!this.editStart) {
            this.showMenu();
        }
        // this / e.target is current target element.

        if (e.preventDefault) {
            e.preventDefault();
        }
        if (e.stopPropagation) {
            e.stopPropagation();
        }

        let types: string[] = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        $.each(e.dataTransfer.types, function (idx, dtype) {
            // special treatment for firefox (in normal browsers the array is a real array :-)
            types.push(dtype);
        });

        let html = types.indexOf("text/html");
        let text = types.indexOf("text/plain");
        let uri = types.indexOf("text/uri-list");
        let files = types.indexOf("Files");
        let image = -1;

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        $.each(types, function (idx, type): any {
            if (type.indexOf("image/") === 0) {
                image = idx;
                return false;
            }
        });
        if (image !== -1) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        } else if (uri !== -1 && Tasks.externalItemFromUrl(e.dataTransfer.getData("text/uri-list"))) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.ddCreateLink(e.dataTransfer.getData("text/html"), e.dataTransfer.getData("text/uri-list"), e);
        } else if (files !== -1) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.ddUploadFiles(e.dataTransfer.files, e);
        } else if (html !== -1) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.ddUploadHTML($(e.dataTransfer.getData("text/html")), e);
        } else if (text !== -1) {
            (<Summernote.editor>this._editor).insert(
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                $("<span>").html(e.dataTransfer.getData("text/plain"))[0],
                e,
                this.lastClient,
            );
        }

        return false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private addVerticalResizer() {
        let that = this;

        if (!this.resizable) {
            return;
        }

        let resizer = $("<div class='rt_resizer'>");
        resizer.append(
            $("<div class='rt_resizer_handle'>").tooltip({
                title: "drag to resize. shift drag, to set project default.",
            }),
        );
        let top = 0;
        let left = 0;
        let height = 0;
        this._editor.parent(".baseControl").append(resizer);
        resizer.draggable({
            start: function (event, ui) {
                top = ui.offset.top;
                left = ui.position.left;
                resizer.css("position", "initial");

                height = that.editable.height();
            },
            drag: function (event, ui) {
                let delta = top - ui.offset.top;
                ui.position.left = left;
                let newHeight = height - delta;
                if (newHeight < 50) {
                    newHeight = 50;
                }
                that.editable.height(newHeight);
            },
            stop: function () {
                if (globalMatrix.globalShiftDown) {
                    globalMatrix.projectStorage.setItem(
                        "eh" + that.settings.fieldId,
                        (that.editable.height() + that.heightDelta).toString(),
                    ); // set default for field
                    globalMatrix.projectStorage.setItem("eh" + that.settings.fieldId + "_" + that.settings.id, ""); // reset field
                } else {
                    globalMatrix.projectStorage.setItem(
                        "eh" + that.settings.fieldId + "_" + that.settings.id,
                        (that.editable.height() + that.heightDelta).toString(),
                    ); // set field
                }
            },
        });
    }
}
