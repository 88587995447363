/* MATRIX-7565: Port of CUSTOM-241 as a hidden feature.
It's activated by a settings for the project called "hazRiskImport".
It adds a menu item to the Tools menu. When clicked, it will update the risk field of all items in the folder that have a risk field with a hazard field.
The plugin is configured in the settings of the project. It has the following settings:
{
  "hazards_help":"category with the hazards defined",
  "hazards":"HAZ",
  "hazardsLegacyFieldName_help":"name of field with the legacy hazard id in the hazard category",
  "hazardsLegacyFieldName":"Legacy Id",
  "users":[
    "testing"], // list of users that are allowed to run the tool
  "RISK":{
    "menu_help":"what to show in menu",
    "menu":"HAZ completion",
    "dropdown_help":"name of drop down with selection of hazard",
    "dropdown":"hazardTitle",
    "legacyHazardRiskProperty_help":"property in the imported risk with the legacy id",
    "legacyHazardRiskProperty":"LegacyHazID"
    dirtyLabel: "dirty" // optional - if set, the label will be added to the item if the values were changed
  },
  "DFMEA":{
    ...
  },
  "PFMEA":{
    ...
  }
}
*/
import {
    app,
    ControlState,
    globalMatrix,
    IAnyMap,
    IItem,
    IItemGet,
    matrixApplicationUI,
    matrixSession,
    restConnection,
} from "../../../globals";
import { IPlugin, plugins } from "../../../common/businesslogic";
import { ml } from "../../../common/matrixlib";
import { ItemControl } from "../../../common/UI/Components";
import { UIToolsConstants } from "../../../common/matrixlib/MatrixLibInterfaces";
import { XRGetProject_Needle_TrimNeedle, XRTrimNeedle } from "../../../RestResult";
import { IRiskValue } from "../../../common/UI/Controls/riskCtrl2";

interface IConf {
    hazards_help: string;
    hazards: string;
    hazardsLegacyFieldName_help: string;
    hazardsLegacyFieldName: string;
    users: string[];
    // category specific settings - key is the category. Types after | exist because of the legacy code
    [key: string]: IConfigPerCat | string | string[];
}

export interface IConfigPerCat {
    menu_help: string;
    menu: string;
    dropdown_help: string;
    dropdown: string;
    legacyHazardRiskProperty_help: string;
    legacyHazardRiskProperty: string;
    dirtyLabel?: string;
}

async function runHazRiskUpdater(conf: IConf, itemIds: string[]): Promise<void> {
    let updater = $("<div style='position:absolute;top:0;left:-10000px;width:1000px;'>").appendTo($("body"));

    let dlg = $("<div>").appendTo("body").addClass("dlg-v-scroll");
    let container = $("<div>");

    ml.UI.showDialog(
        dlg,
        "Setting hazards in risk items...",
        container,
        $(document).width() * 0.9,
        app.itemForm.height() * 0.9,
        [
            {
                text: "Ok",
                class: "btnDoIt",
                click: (): void => {
                    dlg.remove();
                },
            },
        ],
        UIToolsConstants.Scroll.Vertical,
        false, // autoResize
        true, // maximizeButton
        () => {
            dlg.remove();
        }, // close
        () => {}, // open
        () => {}, // resize
        true, // noCloseOnEscape
    );

    async function reIndexRisks(): Promise<void> {
        let hazardField = globalMatrix.ItemConfig.getFieldByName(conf.hazards, conf.hazardsLegacyFieldName)?.id;
        if (!hazardField) {
            console.log(`${conf.hazardsLegacyFieldName} is not a field in ${conf.hazards}`);
            return;
        }

        let hazards: XRTrimNeedle = <XRTrimNeedle>(
            await restConnection.getProject(`needle?search=mrql:category=${conf.hazards}&fieldsOut=*`)
        );
        console.log(hazards?.needles?.filter);
        reIndexRisk(hazards, hazardField, itemIds, 0);
    }

    // run
    async function reIndexRisk(
        hazards: XRTrimNeedle,
        hazardField: number,
        todo: string[],
        index: number,
    ): Promise<void> {
        if (index >= todo.length) {
            container.append("<p><b>DONE - all items have been processed. Check console for details</b></p>");
            console.log("DONE - all items have been updated");
            updater.remove();
            return;
        }
        updater.html("");
        let itemId = todo[index];
        let category = ml.Item.parseRef(itemId).type;

        if (!conf[category]) {
            console.log(category + " has no config for update. ignoring it");
            reIndexRisk(hazards, hazardField, todo, index + 1);
            return;
        }

        let riskFields = globalMatrix.ItemConfig.getFieldsOfType("risk2", category);
        if (riskFields.length === 0) {
            console.log(itemId + " has no risk field. ignoring it");
            reIndexRisk(hazards, hazardField, todo, index + 1);
            return;
        }
        let riskField = riskFields[0].field.id;

        app.getItemAsync(itemId).done(async (item): Promise<void> => {
            if (item === undefined) {
                return;
            }

            let riskVal: IRiskValue = JSON.parse(item[riskField]);
            // console.log(riskVal);
            let legacyHazardId = riskVal.factors?.filter(
                (f) => f.type === (conf[category] as IConfigPerCat).legacyHazardRiskProperty,
            )[0]?.value;
            if (!legacyHazardId) {
                console.log(itemId + " has no more legacy hazard id. nothing to do");
                container.append(
                    `<div>${item.id} (${index + 1}/${
                        todo.length
                    }). Warning: has no legacy hazard id. Nothing to do.</div>`,
                );
                reIndexRisk(hazards, hazardField, todo, index + 1);
                return;
            }
            let hazardItems = hazards.needles.filter(
                (hazard) =>
                    hazard.fieldVal.filter((fv) => fv.id === hazardField && fv.value === legacyHazardId).length === 1,
            );
            if (hazardItems.length === 0) {
                console.log(
                    `There is no ${conf.hazards} item with field ${conf.hazardsLegacyFieldName} === ${legacyHazardId}`,
                );
                container.append(
                    `<div>${item.id} (${index + 1}/${
                        todo.length
                    }). Warning: Hazard with legacy id ${legacyHazardId} does not exist or was not published.</div>`,
                );
                reIndexRisk(hazards, hazardField, todo, index + 1);
                return;
            }
            let hazardId = ml.Item.parseRef(hazardItems[0].itemOrFolderRef).id;

            console.log(
                `${itemId} has legacy hazard id '${legacyHazardId}' which is defined in ${hazardId}. updating the risk....`,
            );
            let before = readFromSaved(riskVal, conf[category] as IConfigPerCat);

            plugins.init(item);
            let newItem: ItemControl = new ItemControl({
                control: updater,
                controlState: ControlState.DialogEdit,
                parent: app.getParentId(itemId),
                type: category,
                isItem: true,
                item: item,
                changed: (): void => {},
            });
            matrixApplicationUI.lastMainItemForm = newItem;
            window.setTimeout(async () => {
                // simulate the user selects the hazard
                let dropdown = $(`select[name="${(conf[category] as IConfigPerCat).dropdown}"]`, updater);
                dropdown.val(hazardId.replace("-", "_"));
                dropdown.trigger("change");

                // now we probably need to save
                if (await newItem.needsSave()) {
                    console.log(itemId + " changed and needs to save");
                    try {
                        let updatedItem = (await newItem.saveAsync(category, "apply legacy hazard")) as IItemGet;
                        container.append(`<div>${item.id} (${index + 1}/${todo.length}). Hazard applied!</div>`);
                        let newRiskVal = JSON.parse(updatedItem[riskField]) as IRiskValue;
                        let after = readFromSaved(newRiskVal, conf[category] as IConfigPerCat);

                        if (JSON.stringify(before) === JSON.stringify(after)) {
                            console.log(itemId + " was updated - existing values were good");

                            reIndexRisk(hazards, hazardField, todo, index + 1);
                        } else {
                            console.log(itemId + " was updated - existing values were changed");
                            console.log("before");
                            console.log(before);
                            console.log("after");
                            console.log(after);
                            if ((conf[category] as IConfigPerCat).dirtyLabel !== undefined) {
                                let labels: string[] = [];
                                if (updatedItem.labels !== undefined) {
                                    labels = updatedItem.labels;
                                }
                                labels.push((conf[category] as IConfigPerCat).dirtyLabel!);
                                let changes = { id: itemId, onlyThoseFields: 1, labels: labels.join(",") };
                                app.updateItemInDBAsync(changes, "fields changed while setting hazard!").done(() => {
                                    reIndexRisk(hazards, hazardField, todo, index + 1);
                                });
                            } else {
                                reIndexRisk(hazards, hazardField, todo, index + 1);
                            }
                        }
                    } catch (e) {
                        console.log(itemId + " COULD NOT BE SAVED!");
                        container.append(`<div>${itemId} (${index + 1}/${todo.length}). Error: Failed to save!</div>`);
                        reIndexRisk(hazards, hazardField, todo, index + 1);
                    }
                } else {
                    console.log(itemId + " was not changed (hazard not known or already set)");
                    container.append(
                        `<div>${itemId} (${index + 1}/${todo.length}). Item did not change / hazard set already.</div>`,
                    );
                    reIndexRisk(hazards, hazardField, todo, index + 1);
                }
            }, 222);
        });
        return;
    }

    /* kinda obsolete - not a requirement anymore */
    function readFromSaved(riskVal: IRiskValue, catConf: IAnyMap): IAnyMap {
        let before: IAnyMap = {};
        // checkFactors:["Hazard","CriticalHFE"],
        for (let factor of catConf.checkFactors ? catConf.checkFactors : []) {
            before[factor] = riskVal.factors?.filter((f) => f.type === factor)[0]?.value;
        }
        // checkWeights:["Hazard/severity","HazSituation/p2"]
        for (let factorWeight of catConf.checkWeights ? catConf.checkWeights : []) {
            let factor = factorWeight.split("/")[0];
            let weight = factorWeight.split("/")[1];

            before[factorWeight] = riskVal.factors
                ?.filter((f) => f.type === factor)[0]
                ?.weights.filter((f) => f.type === weight)[0]?.value;
        }

        return before;
    }
    updater.css("width", 1000);
    reIndexRisks();
}

class HazRiskUpdaterImpl implements IPlugin {
    private item: IItem | undefined = undefined;
    isDefault = true;

    conf: IConf | undefined = undefined;
    initItem(item: IItem, jui: JQuery): void {
        this.item = item;
    }

    initProject(): void {
        this.conf = globalMatrix.ItemConfig.getSettingJSON("hazRiskImport") as IConf;
    }
    supportsControl(): boolean {
        return false;
    }
    updateMenu(ul: JQuery): void {
        let itemId = this.item?.id !== undefined ? this.item?.id : "";
        // nothing selected, not configured => nothing to do
        if (this.item === undefined || this.conf === undefined || itemId === "" || this.item.type === undefined) {
            return;
        }

        if (this.conf.users && this.conf.users.length && this.conf.users.indexOf(matrixSession.getUser()) === -1) {
            return;
        }
        // category not configured => nothing to do
        let category = this.item.type;
        if (!this.conf[category]) {
            return;
        }

        // check that user can modify items
        if (!matrixSession.isEditor()) {
            console.log("you have no rights to run risk update script");
            return;
        }

        let menuEntry = (this.conf[category] as IConfigPerCat)["menu"]
            ? (this.conf[category] as IConfigPerCat)["menu"]
            : "update risk/fmea from hazards";
        $(`<li><span class="toolmenu">${menuEntry}</span></li>`)
            .appendTo(ul)
            .click((event) => {
                let items = app.getChildrenIdsRec(itemId);
                runHazRiskUpdater(this.conf!, items);
            });
    }
}

let HazRiskUpdater = new HazRiskUpdaterImpl();
// register the engine as plugin
export function initialize(): void {
    plugins.register(HazRiskUpdater);
}
