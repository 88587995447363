import { ml } from "../../core/common/matrixlib";
import { UIToolsConstants } from "../../core/common/matrixlib/MatrixLibInterfaces";
import { PrintProjectUIMods } from "../../core/common/UI/Controls/PrintProjectUIMods";
import { JsonEditor } from "../../core/common/UI/JsonEditor";
import { app, ControlState, globalMatrix } from "../../core/globals";
import { JsonEditorValidation } from "../../jsonvalidation/JsonValidator";
import {
    IPrintCustomFormatter,
    IPrintFormatter,
    IPrintFormatterFields,
    IPrintFormatterTraces,
    IPrintFormatterBlock,
    IPrintItemMacro,
    IPrintFormatterList,
    IPrintFormatterTable,
    IPrintFunctionMacro,
    IPrintFormatterSubTable,
    IPrintConditionParams,
} from "../../core/printinterface/PrintFormatter";
import {
    IGlobalPrintFunctionParams,
    IPrintFunctionMap,
    IConditionFunctionMap,
    IPrintFunctionParamsOverwrites,
    IPrePostProcessorExecParams,
    IPrintFunctionParams,
    IPrintBaseFunction,
    IPrintFunction,
    IConditionFunction,
    IPrintBaseFunctionMap,
} from "../../core/printinterface/PrintFunction";
import {
    IPrintIteratorMap,
    IPrintItemIteratorParams,
    IPrintTracesIterator,
    IPrintIterator,
    IPrintItemIterator,
    IPrintLabelIterator,
    IPrintFieldIterator,
} from "../../core/printinterface/PrintIterators";
import { IPrintTableMacro, IPrintMacroFunction, IPrintMacroParams } from "../../core/printinterface/PrintMacros";
import {
    IPrintProcessor,
    IPrintGlobals,
    ICustomSection,
    IProcessResult,
    PrintFindAllScriptsRegex,
    IPrintProcessorOptions,
    IPrintConfig,
    globalPrintConfig,
    setGlobalPrintConfig,
} from "../../core/printinterface/PrintProcessorInterfaces";
import { IPrintSorterMap, IPrintSorter } from "../../core/printinterface/PrintSorter";
import { IDropdownOption } from "../../core/ProjectSettings";
import { IAttributePrimitiveParams } from "./functions/items/AttributePrimitive";
import { printProcessorRegistry } from "./PrintProcessorRegistry";

export type { IPrintRenderedCell, IPrintRowContent };
export { PrintProcessor };

interface IPrintRenderedCell {
    rowspan: number;
    content: string;
    classes: string[];
    style: string;
}

interface IPrintRowContent {
    isFolderRow: boolean;
    rowBefore: string;
    rowAfter: string;
    cells: string[]; // each cell is an <td> completely ready
}

/************************************** Processor class  ********************************************/

class PrintProcessor implements IPrintProcessor {
    // if it wasn't reassigned it's your fault ¯\_(ツ)_/¯
    private onError: (message: string) => void = () => {
        throw new Error("onError is not defined");
    };
    private possibleTargets: string[] = [];
    // TODO: rename mf to something more meaningful
    private mf?: JQuery;
    public globals?: IPrintGlobals;

    private functionDefaults?: IGlobalPrintFunctionParams;

    static itemIterators: IPrintIteratorMap = {};
    static labelIterators: IPrintIteratorMap = {};
    static fieldIterators: IPrintIteratorMap = {};
    static itemSorter: IPrintSorterMap = {};

    static functions: IPrintFunctionMap = {};
    static conditions: IConditionFunctionMap = {};

    private formatter: IPrintCustomFormatter = {
        items: {},
        functionDefaults: { debug: 0 },
    };

    constructor() {
        let that = this;
    }

    private stylesheets: { [key: string]: string } = {};

    /*******************************************************************************
     *
     *  Iterator Blocks
     *
     ***************************************************************************** */

    // init the processing
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    prepareProcessing(mf: JQuery, onError: (message: string) => void, format: string) {
        let that = this;

        // reset default settings
        // TODO: add proper defaults
        this.functionDefaults = {} as IGlobalPrintFunctionParams;
        this.functionDefaults.outputFormat = format;

        this.onError = onError;
        this.mf = mf;
        this.globals = {
            itemMap: {},
            riskControlCategories: {},
            categories: {},
            down: {},
            up: {},
            children: {},
            fieldsPerCategory: {},
            fieldIdByLabel: {},
            fieldDefById: {},
            riskConfigs: {},

            lastItem: "",
            lastFields: {},

            count: 0,
        };

        // adding a bunch of "!" because TS do not pick up the assignement above
        $.each($(mf).children("matrix_filter").children("category"), function (cidx, cat) {
            $.each($("item", $(cat)), function (idx, item) {
                that.globals!.itemMap[item.getAttribute("ref")] = $(item);
            });
            $.each($("folder", $(cat)), function (idx, folder) {
                that.globals!.itemMap[folder.getAttribute("ref")] = $(folder);
            });
            let category = cat.getAttribute("label");
            that.globals!.categories[category] = $(cat);
            that.globals!.riskControlCategories[category] = that.getRiskControlCategories($(cat));
        });
    }

    // get risk controls for a category (empty array if category is no risk)
    private getRiskControlCategories(category: JQuery): string[] {
        // check if this item has a risk field
        let riskFields = category.children(`field_def[field_type="risk2"]`);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (riskFields.length == 0) {
            return [];
        } // no risk field

        let localConfigs = riskFields.find(`param_xml riskConfig`);
        if (localConfigs.length) {
            let riskCategories: string[] = [];
            $.each(localConfigs.find("mitigationTypes type"), function (idx, type) {
                riskCategories.push($(type).text());
            });

            return riskCategories;
        }

        return [];
    }

    // convert a section
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async processSection(
        formatter: IPrintCustomFormatter,
        section: ICustomSection,
        projectOverwrites: IPrintFunctionParamsOverwrites,
        selection: string[],
        possibleTargets: string[],
    ): Promise<IProcessResult> {
        // shouldn't happen
        if (!this.functionDefaults) {
            return {
                redlining: [],
                html: "",
            };
        }

        let that = this;

        this.formatter = formatter;

        // get possible targets
        this.possibleTargets = [];
        for (const target of possibleTargets) {
            this.enumeratePossibleTargets(target);
        }

        // build the parameters for functions
        this.functionDefaults.customer = formatter.functionDefaults ? formatter.functionDefaults : { debug: 0 };
        this.functionDefaults.project = projectOverwrites ? projectOverwrites : { debug: 0 };
        this.functionDefaults.section = section.functionDefaults ? section.functionDefaults : { debug: 0 };

        let result = "";

        if (section.description) {
            result += section.description;
        }
        if (section.descriptionContent && selection.length) {
            result += section.descriptionContent;
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (section.descriptionNoContent && selection.length == 0) {
            result += section.descriptionNoContent;
        }

        let redlining: string[] = [];

        if (
            this.mf &&
            section.functionDefaults &&
            section.functionDefaults.preProcessors &&
            (<IPrePostProcessorExecParams[]>section.functionDefaults.preProcessors).length
        ) {
            for (let processor of <IPrePostProcessorExecParams[]>section.functionDefaults.preProcessors) {
                printProcessorRegistry.executePre(processor, this.mf);
            }
        }

        // Filter out all selections that are not in the filter.xml (e.g. filter by label etc)
        selection = selection.filter((id) => this.globals?.itemMap.hasOwnProperty(id));

        if (selection.length) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (section.formatter.indexOf(PrintProjectUIMods.CAT_SEQUENTIAL + "-") == 0) {
                result += await this.processListFormatter({ renderItem: section.formatter }, selection, 1, redlining);
            }
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (section.formatter.indexOf(PrintProjectUIMods.CAT_TABLE + "-") == 0) {
                result += await this.processTableFormatter({ renderItem: section.formatter }, selection, redlining);
            } else {
                // no table / no list defined that makes it a simple text section
                result += "";
            }
        }

        if (
            section.functionDefaults &&
            section.functionDefaults.postProcessors &&
            (<IPrePostProcessorExecParams[]>section.functionDefaults.postProcessors).length
        ) {
            for (let processor of <IPrePostProcessorExecParams[]>section.functionDefaults.postProcessors) {
                result = printProcessorRegistry.executePost(processor, result);
            }
        }

        try {
            //MATRIX-7847: Avoid parsing errors in edge cases
            const container = document.createElement("div");
            container.innerHTML = result;
            //MATRIX-7167 : Items are wrapped in a div with data-print=true. Let remove it.
            let $result = $("<div></div>").append(container);
            $result.find('div[data-print="true"]').each((id, elem) => {
                let $this = $(elem); // Current div with data-print="true"
                // Move all children of this div to its parent
                if ($this.children().length > 0) {
                    $this.children().unwrap();
                }

                // No need to process div with no children.
            });
            result = $result.html();
        } catch (e) {
            console.error("Error parsing result content: ", e, result);
        }

        return {
            html: result,
            redlining: redlining,
        };
    }

    async getTableData(tableId: string, selection: string[]): Promise<string> {
        return this.processTableFormatter({ renderItem: tableId }, selection, null);
    }

    /********************************************************************************
     *  Main Processing functions to handle items from the print project
     ********************************************************************************/
    // processes a single item (don't distinguish between folder and item)
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async processItem(formatter: IPrintFormatter, itemOrFolder: string) {
        let category = ml.Item.parseRef(formatter.uid).type;

        switch (category) {
            case "FIELDS":
                return await this.processFieldsFormatter(<IPrintFormatterFields>formatter, itemOrFolder);
            case "TRACES":
                return await this.processTraceFormatter(<IPrintFormatterTraces>formatter, itemOrFolder, 1);
            case "BLOCK":
                return await this.processBlockFormatter(<IPrintFormatterBlock>formatter, itemOrFolder);
            case "SEQUENTIAL":
                return await this.processListFormatter({ renderItem: formatter.uid }, [itemOrFolder], 1, null);
            case "TABLE":
                return await this.processTableFormatter({ renderItem: formatter.uid }, [itemOrFolder], null);
        }

        this.onError(`${itemOrFolder} cannot be used as print macro!`);
        return "";
    }

    // process a block (something building block with or without a condition when to render it)
    private async processBlockFormatter(formatter: IPrintFormatterBlock, itemOrFolder: string): Promise<string> {
        let code = "";
        let conditionFormatter = <IPrintFormatterBlock>(<unknown>formatter);
        if (
            !this.globals ||
            !(await this.evaluateCondition(
                conditionFormatter.condition,
                conditionFormatter.params,
                itemOrFolder,
                this.globals.itemMap[itemOrFolder],
                [],
            ))
        ) {
            return ""; // show nothing
        }

        code += await this.processMacros(formatter.render, formatter.uid, formatter.params, itemOrFolder, 1, null);

        return code;
    }

    // process all fields / labels of an item
    private async processFieldsFormatter(formatter: IPrintFormatterFields, itemOrFolder: string): Promise<string> {
        let code = "";
        const fieldIterator = formatter.iterator ? PrintProcessor.getFieldIterator(formatter.iterator) : null;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (fieldIterator != null && this.functionDefaults && this.mf && this.globals) {
            let fields = await fieldIterator.iterate(
                this.functionDefaults,
                formatter.params,
                itemOrFolder,
                this.mf,
                this.globals,
                this.possibleTargets,
                this.onError,
            );
            if (formatter.before) {
                code += formatter.before;
            }
            for (const field of fields) {
                code += await this.processMacros(
                    formatter.render,
                    formatter.uid,
                    { fieldInfo: field },
                    itemOrFolder,
                    0,
                    null,
                );
            }
            if (formatter.after) {
                code += formatter.after;
            }

            return code;
        }

        const labelIterator = formatter.iterator ? PrintProcessor.getLabelIterator(formatter.iterator) : null;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (labelIterator != null && this.functionDefaults && this.mf && this.globals) {
            let labels = labelIterator.iterate(
                this.functionDefaults,
                formatter.params,
                itemOrFolder,
                this.mf,
                this.globals,
                this.possibleTargets,
                this.onError,
            );

            if (formatter.before) {
                code += formatter.before;
            }
            for (const label of labels) {
                // copy the formatter
                let labelFormatter = ml.JSON.clone({ ...formatter, ...{} });

                // replace placeholders

                labelFormatter.render = labelFormatter.render.replace(/_labelIconText_/g, label.icon);
                labelFormatter.render = labelFormatter.render.replace(
                    /_labelIcon_/g,
                    "<span class='" + label.icon + "</span",
                );
                labelFormatter.render = labelFormatter.render.replace(/_labelText_/g, label.printName);

                // add the details about the field to the macro (which acts )

                code += await this.processMacros(
                    labelFormatter.render,
                    formatter.uid,
                    { label: label },
                    itemOrFolder,
                    0,
                    null,
                );
            }
            if (formatter.after) {
                code += formatter.after;
            }
            return code;
        }

        this.onError(`${itemOrFolder} has no field or label iterator specified!`);
        return "";
    }

    // process all up or down traces of an item
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async processTraceFormatter(formatter: IPrintFormatterTraces, item: string, depth: number) {
        let code = "";

        const traceIterator = formatter.iterator ? PrintProcessor.getItemIterator(formatter.iterator) : null;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (traceIterator != null && this.functionDefaults && this.mf && this.globals) {
            let children = await traceIterator.iterate(
                this.functionDefaults,
                formatter.params,
                item,
                this.mf,
                this.globals,
                this.possibleTargets,
                this.onError,
            );

            if (formatter.before) {
                code += formatter.before;
            }
            for (const childIt of children) {
                // in case the iterator iterates over the rows of a table the item to report about stays the same
                let child = traceIterator.tableRowIterator ? item : childIt;
                // set the row if we iterate over table rows
                if (traceIterator.tableRowIterator) {
                    this.functionDefaults.tableRow = 1 + Number(childIt);
                } else {
                    this.functionDefaults.tableRow = 0;
                }
                // copy the formatter
                let childFormatter = <IPrintFormatterTraces>(<unknown>ml.JSON.clone({ ...formatter, ...{} }));

                code += await this.processMacros(
                    childFormatter.item,
                    formatter.uid,
                    childFormatter.params,
                    child,
                    depth,
                    null,
                );
            }
            if (formatter.after) {
                code += formatter.after;
            }
            return code;
        }
        this.onError(`${item} has no trace iterator specified!`);
        return "";
    }

    // processes an array of items/folders to create a list
    private async processListFormatter(
        macro: IPrintItemMacro,
        selection: string[],
        depth: number,
        redlining: string[] | null,
    ): Promise<string> {
        let formatter = <IPrintFormatterList>(<unknown>this.getItemFormatter(macro.renderItem));
        if (!formatter) {
            this.onError(`Cannot find formatter '${macro.renderItem}'!`);
            return "";
        }
        if (this.functionDefaults?.outputFormat === "xlsx") {
            try {
                const formatNode = $(formatter.item)[0];
                if (formatNode.nodeName !== "SPAN") {
                    formatter.item = formatNode.textContent + "<br/><br/>";
                }
            } catch {
                // can't parse the content, ignore
            }
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let code = depth == 1 || !formatter.hideFolder ? formatter.before : "";

        for (const itemOrFolder of selection) {
            if (ml.Item.parseRef(itemOrFolder).isFolder) {
                code +=
                    this.addScriptInfo(macro.renderItem, itemOrFolder) +
                    (await this.processMacros(
                        this.applyDepth(formatter.folder, depth),
                        macro.renderItem,
                        macro,
                        itemOrFolder,
                        depth,
                        redlining,
                    ));
            } else {
                if (redlining) {
                    redlining.push(itemOrFolder);
                }
                code +=
                    this.addScriptInfo(macro.renderItem, itemOrFolder) +
                    (await this.processMacros(
                        this.applyDepth(formatter.item, depth),
                        macro.renderItem,
                        macro,
                        itemOrFolder,
                        depth,
                        redlining,
                    ));
            }
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        code += depth == 1 || !formatter.hideFolder ? formatter.after : "";
        return code;
    }

    // processes an array of items/folders to create a table
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async processTableFormatter(macro: IPrintTableMacro, selection: string[], redlining: string[] | null) {
        let formatter = <IPrintFormatterTable>(<unknown>this.getItemFormatter(macro.renderItem));
        if (!formatter) {
            this.onError(`Cannot find formatter '${macro.renderItem}'!`);
            return "";
        }

        // create one big list of rows, with its cells each
        let rowsContent: IPrintRowContent[] = [];

        await this.addRows(rowsContent, formatter, macro.renderItem, macro, selection, 0, redlining);

        const tableBeforeWithClasses =
            this.functionDefaults?.outputFormat === "xlsx"
                ? this.extractClassesFromTable(formatter.before)
                : formatter.before;

        // assemble the whole table
        let code = this.addScriptInfo(macro.renderItem, "") + tableBeforeWithClasses;

        for (let rc of rowsContent) {
            code += rc.rowBefore;
            let mergedCells = this.mergeCells(rc.cells);
            for (let cell of mergedCells) {
                code += cell;
            }
            code += rc.rowAfter;
        }
        code += formatter.after;

        return code;
    }

    /********************************************************************************
     *  processing of "json" macros like {execute:"function", parameters:{...}}
     ********************************************************************************/

    // this converts one line like <li>$[execute:"function", parameters:"{...}"]</li> to <li><a href="">REQ-1</a></li>
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async processMacros(
        formatting: string,
        callerId: string,
        printFunctionParams: IPrintFunctionParams,
        itemOrFolder: string,
        depth: number,
        redlining: string[] | null,
    ): Promise<string> {
        let scripts: RegExpMatchArray | null = null;

        if (printFunctionParams) {
            printFunctionParams.recursionDepth = depth;
        }

        try {
            //$.parseXML("<xml>" + formatting + "</xml>");
            scripts = formatting.match(PrintFindAllScriptsRegex);
        } catch (ex) {
            this.onError(`The render script is missing.`);
            console.log(ex);
            return "";
        }

        if (!scripts) {
            //nothing to do we are all set
            return formatting;
        }

        // now each script can be another block called for the current item, a primitive
        for (const script of scripts) {
            try {
                let jsonString = script.replace("$[", "{").replace("]$", "}");
                let macro = JSON.parse(jsonString);

                if (macro.renderItem) {
                    formatting = await this.processPrintFormatterMacro(macro, script, formatting, itemOrFolder);
                } else if (macro.renderFunction) {
                    formatting = await this.processFunctionMacro(
                        macro,
                        script,
                        formatting,
                        itemOrFolder,
                        depth,
                        callerId,
                        printFunctionParams,
                        redlining,
                    );
                } else if (macro.renderfunction) {
                    macro.renderFunction = macro.renderfunction;
                    formatting = await this.processFunctionMacro(
                        macro,
                        script,
                        formatting,
                        itemOrFolder,
                        depth,
                        callerId,
                        printFunctionParams,
                        redlining,
                    );
                } else {
                    this.onError(`Don't understand macro: "${script}".`);
                    return "";
                }
            } catch (ex) {
                console.error(ex);
                this.onError(`Bad macro "${script}"`);
                return "";
            }
        }

        return formatting;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async processPrintFormatterMacro(
        macro: IPrintItemMacro,
        script: string,
        formatting: string,
        itemOrFolder: string,
    ) {
        let formatter = this.getItemFormatter(macro.renderItem);
        if (!formatter) {
            this.onError(`There is not template: "${macro.renderItem}" - verify PRINT project.`);
            return formatting.replace(script, `Error in "${script}": unknown template.`);
        }

        formatting =
            this.addScriptInfo(macro.renderItem, itemOrFolder) +
            formatting.replace(script, await this.processItem(formatter, itemOrFolder));
        return formatting;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async processFunctionMacro(
        macro: IPrintFunctionMacro,
        script: string,
        formatting: string,
        itemOrFolder: string,
        depth: number,
        callerId: string,
        printFunctionParams: IPrintFunctionParams,
        redlining: string[] | null,
    ): Promise<string> {
        // shouldn't happen
        if (!this.functionDefaults || !this.mf || !this.globals) {
            return "";
        }

        // check if this function call is to render children of a folder
        let childIterator = PrintProcessor.getItemIterator(macro.renderFunction, true);
        if (childIterator) {
            let childIteratorParams = <IPrintItemIteratorParams>macro;
            let maxDepth = childIteratorParams.maxDepth;
            if (maxDepth && depth >= maxDepth) {
                // end of recursion
                return formatting.replace(script, "");
            } else {
                // this will be next iteration
                depth++;
                let childIds = await childIterator.iterate(
                    this.functionDefaults,
                    childIteratorParams,
                    itemOrFolder,
                    this.mf,
                    this.globals,
                    this.possibleTargets,
                    this.onError,
                );
                this.sortItems(childIds, childIteratorParams);
                return (
                    this.addScriptInfo(macro.renderFunction, childIds.join(",")) +
                    formatting.replace(
                        script,
                        await this.processListFormatter({ renderItem: callerId }, childIds, depth, redlining),
                    )
                );
            }
        }

        // check if this a call to recurse into traces
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (macro.renderFunction == "recurseTraces") {
            // TODO: figure out the types. how we're getting IPrintTracesIterator from getItemFormatter?
            let caller = <IPrintTracesIterator>(<unknown>this.getItemFormatter(callerId));
            if (caller && caller.params && (<IPrintItemIteratorParams>caller.params).maxDepth) {
                const params = caller.params as IPrintItemIteratorParams;
                if (params.maxDepth && depth >= params.maxDepth) {
                    // end of recursion
                    return formatting.replace(script, "");
                }
            }
            // this will be next iteration
            depth++;

            let formatter = this.getItemFormatter(callerId);
            if (!formatter) {
                this.onError(`Cannot find formatter '${callerId}'!`);
                return "";
            }

            return formatting.replace(
                script,
                await this.processTraceFormatter(<IPrintFormatterTraces>formatter, itemOrFolder, depth),
            );
        }

        // "normal" function
        const printer = PrintProcessor.getFunction(macro.renderFunction);
        if (!printer) {
            this.onError(`There is no function: "${macro.renderFunction}".`);
            return formatting.replace(script, `Error in "${script}": unknown function.`);
        }

        const rendered = await printer.renderAsync(
            this.functionDefaults,
            ml.JSON.clone({ ...macro, ...printFunctionParams }),
            itemOrFolder,
            this.globals.itemMap[itemOrFolder],
            this.mf,
            this.globals,
            this.possibleTargets,
            this.onError,
            this,
        );
        return this.addScriptInfo(macro.renderFunction, itemOrFolder) + formatting.replace(script, rendered);
    }

    /********************************************************************************
     *  Helper for building tables
     ********************************************************************************/

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private mergeCells(cells: string[]) {
        if (!cells || cells.length < 2) {
            return cells;
        }
        let merged: string[] = [];
        merged[0] = cells[0];
        for (let col = 1; col < cells.length; col++) {
            const cell = cells[col];
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (cell.indexOf("_MERGE_") != -1) {
                // this cell should be merged with the one before
                merged[merged.length - 1] = $(merged[merged.length - 1]).attr(
                    "COLSPAN",
                    Number($(merged[merged.length - 1]).attr("COLSPAN")) + 1,
                )[0].outerHTML;
            } else {
                merged.push(cell);
            }
        }
        return merged;
    }

    private extractClassesFromTable(beforeString: string): string {
        let html = $(beforeString);
        let table: HTMLElement;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (html.length == 0) {
            console.error("No html nodes in header");
            return beforeString;
        }
        if (html.get(0).nodeName === PrintProjectUIMods.CAT_TABLE) {
            table = html.get(0);
        } else {
            const tables = html.find("table");
            if (tables.length === 0) {
                console.error("No tables in the header");
                return beforeString;
            }
            table = tables[0];
        }

        const cells = $(table).find("td");
        for (let it = 0; it < cells.length; it++) {
            const cell = $(cells[it]);
            const className = `_custom_${it}`;
            this.stylesheets[className] = cell.attr("style");
            cell.attr("style", "");
            cell.addClass(className);
        }

        return "<table>" + html.get(0).innerHTML;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async addRows(
        rowsContent: IPrintRowContent[],
        formatter: IPrintFormatterTable,
        formatterId: string,
        parameters: IPrintTableMacro,
        selection: string[],
        depth: number,
        redlining: string[] | null,
    ) {
        for (const itemOrFolder of selection) {
            let isFolder = ml.Item.parseRef(itemOrFolder).isFolder;

            let subTable = await this.getSubTableCells(
                isFolder ? formatter.subTableFolder : formatter.subTable,
                parameters,
                itemOrFolder,
            );

            let printTableRow = isFolder ? formatter.renderFolderRow : formatter.renderItemRow;

            if (printTableRow && printTableRow.cells.length) {
                let tableForItem: IPrintRenderedCell[][] = [];

                // build the complete table
                for (let rowIdx = 0; rowIdx < Math.max(subTable.length, 1); rowIdx++) {
                    let rowForItem: IPrintRenderedCell[] = [];
                    for (const cell of printTableRow.cells) {
                        let lastAlt = false;
                        if (cell.fetchColumn) {
                            if (rowIdx < subTable.length && subTable[rowIdx].length >= cell.fetchColumn) {
                                rowForItem.push({
                                    rowspan: subTable[rowIdx][cell.fetchColumn - 1].rowspan,
                                    content: subTable[rowIdx][cell.fetchColumn - 1].content,
                                    classes: subTable[rowIdx][cell.fetchColumn - 1].classes,
                                    style: subTable[rowIdx][cell.fetchColumn - 1].style
                                        ? subTable[rowIdx][cell.fetchColumn - 1].style
                                        : "",
                                });
                            } else {
                                rowForItem.push({
                                    rowspan: 1,
                                    content: await this.processMacros(
                                        cell.fetchColumnAlt ?? "",
                                        formatterId,
                                        formatter,
                                        itemOrFolder,
                                        0,
                                        null,
                                    ),
                                    classes: [],
                                    style: "",
                                });
                            }
                        } else {
                            let merge = true;
                            let content = await this.processMacros(
                                cell.render ?? "",
                                formatterId,
                                formatter,
                                itemOrFolder,
                                0,
                                null,
                            );
                            // copied from sub table
                            let classes: string[] = [];
                            let style = "";
                            try {
                                const node = $(content);
                                // This is a single node so we "lift up" the class onto the cell - css should diff between td.class and .class
                                // Now if the node is a div with data-print="true" we should take the inner class
                                if (node.length === 1) {
                                    if (node.attr("data-print") !== "true") {
                                        classes = node.attr("class").split(/\s+/);
                                        style = node.attr("style");
                                    } else {
                                        //Retry with inner content
                                        const innerNode = $(node.html());
                                        if (innerNode.length === 1) {
                                            classes = innerNode.attr("class").split(/\s+/);
                                            style = innerNode.attr("style");
                                        }
                                    }
                                }
                            } catch {
                                // pure string, not html
                            }

                            rowForItem.push({
                                rowspan: merge ? Math.max(subTable.length, 1) : 1,
                                content: content,
                                classes: classes,
                                style: style,
                            });
                        }
                    }
                    tableForItem.push(rowForItem);
                }

                // prepare merging
                let merges: number[] = [];
                for (let colIdx = 0; colIdx < printTableRow.cells.length; colIdx++) {
                    const cell = printTableRow.cells[colIdx];
                    // set merges to 0 if no merges are needed or 1 if it should be merged
                    merges[colIdx] = this.hasMergeMacro(cell.before) || this.hasMergeMacro(cell.after) ? 1 : 0;
                }

                // render the table merging the cells as needed
                for (let rowIdx = 0; rowIdx < tableForItem.length; rowIdx++) {
                    let newRow: IPrintRowContent = {
                        isFolderRow: isFolder,
                        rowBefore: "",
                        rowAfter: "",
                        cells: [],
                    };

                    newRow.rowBefore = await this.processMacros(
                        this.applyDepth(printTableRow.before, depth),
                        formatterId,
                        formatter,
                        itemOrFolder,
                        0,
                        null,
                    );
                    for (let colIdx = 0; colIdx < tableForItem[rowIdx].length; colIdx++) {
                        if (merges[colIdx] <= 1) {
                            // otherwise it's a merged cell
                            let cell = printTableRow.cells[colIdx];
                            // if this is a merged cell, remember how many lines need to merged
                            merges[colIdx] = merges[colIdx] ? tableForItem[rowIdx][colIdx].rowspan : 0;
                            let rowspan = "" + (merges[colIdx] ? merges[colIdx] : 1);
                            let content = (
                                await this.processMacros(
                                    this.applyDepth(cell.before, depth),
                                    formatterId,
                                    formatter,
                                    itemOrFolder,
                                    0,
                                    null,
                                )
                            )
                                .replace(/_merge_/g, rowspan)
                                .replace(/_classes_/g, tableForItem[rowIdx][colIdx].classes.join(" "))
                                .replace(/_style_/g, tableForItem[rowIdx][colIdx].style);
                            content += this.applyDepth(tableForItem[rowIdx][colIdx].content, depth);
                            content += (
                                await this.processMacros(
                                    this.applyDepth(cell.after, depth),
                                    formatterId,
                                    formatter,
                                    itemOrFolder,
                                    0,
                                    null,
                                )
                            ).replace(/_merge_/g, rowspan);
                            newRow.cells.push(content);
                        } else {
                            merges[colIdx]--; // merged cell which was rendered before, so ignore that one
                        }
                    }
                    newRow.rowAfter = await this.processMacros(
                        this.applyDepth(printTableRow.after, depth),
                        formatterId,
                        parameters,
                        itemOrFolder,
                        0,
                        null,
                    );
                    rowsContent.push(newRow);
                }
            }

            if (isFolder) {
                let iterator = PrintProcessor.getItemIterator(formatter.iterator);
                const nonNullParams = formatter.params ?? {};
                let maxDepth = nonNullParams.maxDepth ?? 0; //(nonNullParams && nonNullParams.maxDepth)?nonNullParams.maxDepth:0;

                if (iterator && (!maxDepth || maxDepth > depth) && this.functionDefaults && this.mf && this.globals) {
                    let children = await iterator.iterate(
                        this.functionDefaults,
                        nonNullParams,
                        itemOrFolder,
                        this.mf,
                        this.globals,
                        this.possibleTargets,
                        this.onError,
                    );
                    this.sortItems(children, nonNullParams);
                    await this.addRows(rowsContent, formatter, formatterId, parameters, children, depth + 1, redlining);
                }
            } else {
                if (redlining) {
                    redlining.push(itemOrFolder);
                }
            }
        }
    }

    // processes a sub table returns the cells
    private async getSubTableCells(
        formatterId: string,
        parameters: IPrintMacroParams,
        itemOrFolder: string,
    ): Promise<IPrintRenderedCell[][]> {
        if (!formatterId || !this.functionDefaults || !this.mf || !this.globals) {
            return [];
        }
        const formatter = <IPrintFormatterSubTable>(<unknown>this.getItemFormatter(formatterId));
        if (!formatter) {
            this.onError(`subtable "${formatterId}" does not exist`);
            return [];
        }
        let iterator = PrintProcessor.getItemIterator(formatter.iterator);
        if (!iterator) {
            this.onError(`subtable "${formatterId}" has no existing iterator`);
            return [];
        }

        let cells: IPrintRenderedCell[][] = [];
        let children = await iterator.iterate(
            this.functionDefaults,
            formatter.params,
            itemOrFolder,
            this.mf,
            this.globals,
            this.possibleTargets,
            this.onError,
        );
        this.sortItems(children, formatter.params);

        for (const childIt of children) {
            // in case the iterator iterates over the rows of a table the item to report about stays the same
            let child = iterator.tableRowIterator ? itemOrFolder : childIt;
            // set the row if we iterate over table rows
            if (iterator.tableRowIterator) {
                this.functionDefaults.tableRow = 1 + Number(childIt);
            } else {
                this.functionDefaults.tableRow = 0;
            }

            // maybe this is recursive...
            let subTable = await this.getSubTableCells(formatter.subTable, parameters, child);

            // build the complete table
            let rowspan = Math.max(subTable.length, 1);
            for (let rowIdx = 0; rowIdx < rowspan; rowIdx++) {
                let row: IPrintRenderedCell[] = [];
                for (const cell of formatter.cells) {
                    if (cell.fetchColumn) {
                        row.push({
                            rowspan: rowIdx < subTable.length ? subTable[rowIdx][cell.fetchColumn - 1].rowspan : 1,
                            content:
                                rowIdx < subTable.length
                                    ? subTable[rowIdx][cell.fetchColumn - 1].content
                                    : cell.fetchColumnAlt ?? "",
                            classes: rowIdx < subTable.length ? subTable[rowIdx][cell.fetchColumn - 1].classes : [],
                            style: rowIdx < subTable.length ? subTable[rowIdx][cell.fetchColumn - 1].style : "",
                        });
                    } else {
                        const content = await this.processMacros(cell.render, formatterId, formatter, child, 0, null);
                        let classes: string[] = [];
                        let style = "";
                        try {
                            const node = $(content);
                            // This is a single node so we "lift up" the class onto the cell - css should diff between td.class and .class
                            if (node.length === 1) {
                                classes = node.attr("class").split(/\s+/);
                                style = node.attr("style");
                            }
                        } catch {
                            // pure string, not html
                        }
                        row.push({ rowspan: rowspan, content: content, classes: classes, style: style });
                    }
                }
                cells.push(row);
            }
        }
        return cells;
    }

    /********************************************************************************
     *  Misc helper
     ********************************************************************************/

    public getCustomStylesheet(): string {
        let stylesheet = "";
        Object.keys(this.stylesheets).forEach((key) => {
            if (this.stylesheets[key]) {
                stylesheet += `.${key} {${this.stylesheets[key]}}\n`;
            }
        });
        return stylesheet;
    }

    // this replaces the h_depth_ with macro with h2,h3 or some custom setting
    private applyDepth(content: string, depth: number): string {
        const params: IPrintProcessorOptions = ml.JSON.clone({
            ...this.functionDefaults?.customer["options"],
            ...this.functionDefaults?.project["options"],
            ...this.functionDefaults?.section["options"],
        });
        const headers = params.headers ?? {};
        const maxDepth = headers.maxDepth ?? 0;
        const altBefore = headers.altBefore ?? "";
        const altAfter = headers.altAfter ?? "";

        if (maxDepth <= depth) {
            return content.replace(/_depth_/g, depth.toString()).replace(/_depth1_/g, (1 + depth).toString());
        }
        // the default headers should be replaced by something
        let before = altBefore.replace(/_depth_/g, depth.toString()).replace(/_depth1_/g, (1 + depth).toString());
        let after = altAfter.replace(/_depth_/g, depth.toString()).replace(/_depth1_/g, (1 + depth).toString());

        return content
            .replace(/<h_depth>/g, before)
            .replace(/<\/h_depth>/g, after)
            .replace(/_depth_/g, depth.toString())
            .replace(/<h_depth1>/g, before)
            .replace(/<\/h_depth1>/g, after)
            .replace(/_depth1_/g, (1 + depth).toString());
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private hasMergeMacro(render: string) {
        if (!render) {
            return false;
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return render.indexOf("_merge_") != -1;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private addScriptInfo(fct: string, info: string) {
        if (
            !this.functionDefaults?.customer.debug &&
            !this.functionDefaults?.project.debug &&
            !this.functionDefaults?.section.debug
        ) {
            return "";
        }
        if (
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.functionDefaults.customer.debug == 2 ||
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.functionDefaults.project.debug == 2 ||
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.functionDefaults.section.debug == 2
        ) {
            return "<span style='border:1px;border-radius:4px;padding:1px 4px;font-size:smaller'>" + fct + "</span>";
        }
        return (
            "<span style='border:1px;border-radius:4px;padding:1px 4px;font-size:smaller'>" +
            fct +
            ": " +
            info +
            "</span>"
        );
    }
    // evaluate condition
    // TODO: consume arguments as object
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async evaluateCondition(
        condition: string | undefined,
        params: IPrintConditionParams | undefined,
        itemOrFolderRef: string,
        object: JQuery,
        selection: string[] | null,
    ) {
        if (!condition) {
            // no condition... all good
            return true;
        }
        if (!params) {
            console.error("Condition was provided without condition params");
            throw new Error("Condition was provided without condition params");
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (condition.toLowerCase() == "hasselection") {
            return !params.negate && selection && selection.length > 0;
        }
        let fct = PrintProcessor.getConditionFunction(condition);
        if (!fct || !this.functionDefaults || !this.mf || !this.globals) {
            this.onError(`missing condition function "${condition}"`);
            return true;
        }
        let result = await fct.evaluate(
            this.functionDefaults,
            params,
            itemOrFolderRef,
            object,
            this.mf,
            this.globals,
            this.possibleTargets,
            this.onError,
        );

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return result == !params.negate;
    }

    // also include children of possible target folders
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private enumeratePossibleTargets(target: string) {
        let that = this;

        let itemOrFolder = this.globals?.itemMap[target];
        if (itemOrFolder) {
            this.possibleTargets.push(target);
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (target.indexOf("F-") == 0) {
                $.each($("ITEM,FOLDER", itemOrFolder), function (idx, kid) {
                    that.possibleTargets.push($(kid).attr("ref"));
                });
            }
        }
    }

    // sort items if sorters have been defined
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private sortItems(itemIds: string[], params: IPrintItemIteratorParams) {
        if (params.sorting && this.mf && this.globals) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            for (let sorter of params.sorting.filter((s) => s.sorter != "")) {
                let sortFunction = PrintProcessor.getItemSorter(sorter.sorter);
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (sortFunction == null) {
                    this.onError(`Undefined sort function '${sorter.sorter}'`);
                } else {
                    const innerSortFunction = sortFunction;
                    // TS don't pick up the results of the first condition in this function, hence using "!"
                    itemIds.sort((a, b) =>
                        innerSortFunction.sort(
                            a,
                            b,
                            sorter.descending,
                            sorter.params,
                            this.mf!,
                            this.globals!,
                            this.possibleTargets,
                            this.onError,
                        ),
                    );
                }
            }
        }
    }
    /*********************************** manage formatters ************************************/

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getFunctions(group: string) {
        let filtered: IPrintFunctionMap = {};
        $.each(PrintProcessor.functions, function (uid, fct) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (fct.getGroup().toLowerCase() == group.toLowerCase()) {
                filtered[uid] = fct;
            }
        });
        return filtered;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static functionHasOptions(functionUid: string) {
        return true;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static editFunctionOptions(currentValue: string, onUpdate: (newOptions: string) => void) {
        let json: IPrintMacroFunction = {
            renderFunction: "",
        };
        try {
            json = JSON.parse(currentValue ? currentValue : "{}");
        } catch (ex) {
            ml.UI.showError("invalid parameters", currentValue);
        }
        let uid = json.renderFunction;

        if (!uid) {
            ml.UI.showError("No parameters needed", currentValue);
            return;
        }

        PrintProcessor.showOptionsEditor(uid, currentValue, onUpdate);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static showOptionsEditor(fctName: string, currentValue: string, onUpdate: (newValue: string) => void) {
        let json = {};
        try {
            json = JSON.parse(currentValue ? currentValue : "{}");
        } catch (ex) {
            ml.UI.showError("invalid parameters", currentValue);
        }

        let apiHelp = "";
        let validation: string | JsonEditorValidation | null = null;

        let fieldIterator = PrintProcessor.getFieldIterator(fctName);
        if (
            !globalMatrix.globalCtrlDown &&
            fieldIterator &&
            PrintProcessor.openEditor(fieldIterator, json, (newParams) => onUpdate(JSON.stringify(newParams)))
        ) {
            return;
        } else if (fieldIterator) {
            apiHelp = fieldIterator.getHelp();
        }
        let labelIterator = PrintProcessor.getLabelIterator(fctName);
        if (
            !globalMatrix.globalCtrlDown &&
            labelIterator &&
            PrintProcessor.openEditor(labelIterator, json, (newParams) => onUpdate(JSON.stringify(newParams)))
        ) {
            return;
        } else if (labelIterator) {
            apiHelp = labelIterator.getHelp();
        }
        let itemIterator = PrintProcessor.getItemIterator(fctName, true);
        if (
            !globalMatrix.globalCtrlDown &&
            itemIterator &&
            PrintProcessor.openEditor(itemIterator, json, (newParams) => onUpdate(JSON.stringify(newParams)))
        ) {
            return;
        } else if (itemIterator) {
            apiHelp = itemIterator.getHelp();
            validation = itemIterator.getValidation();
        }
        let condition = PrintProcessor.getConditionFunction(fctName);
        if (
            !globalMatrix.globalCtrlDown &&
            condition &&
            PrintProcessor.openEditor(condition, json, (newParams) => onUpdate(JSON.stringify(newParams)))
        ) {
            return;
        } else if (condition) {
            apiHelp = condition.getHelp();
        }

        let fct = PrintProcessor.getFunction(fctName);
        if (
            !globalMatrix.globalCtrlDown &&
            fct &&
            PrintProcessor.openEditor(fct, json, (newParams) => onUpdate(JSON.stringify(newParams)))
        ) {
            return;
        } else if (fct) {
            apiHelp = fct.getHelp();
        }
        // there's no ui (or no ui wanted) -> plain text editor
        let je = new JsonEditor();
        je.showDialog(
            "Advanced Edit",
            JSON.stringify(json),
            function (newValue: string) {
                onUpdate(newValue);
            },
            { validationFunction: validation, apiHelp: apiHelp },
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static openEditor(
        fct: IPrintBaseFunction,
        params: IAttributePrimitiveParams,
        onUpdate: (newParams: IAttributePrimitiveParams) => void,
    ) {
        let that = this;

        if (!fct.editParams) {
            // there's no UI -> just use the default text editor

            return false;
        }
        let dlg = $("<div>").appendTo($("body"));

        // ************************************************************************************
        // create main UI - a view with two tabs: a UI editor and a json text editor
        // ************************************************************************************

        let ui = $("<div style='height:100%;width:100%'>");

        let tabpanel = $('<div role="tabpanel" class="tabpanel-container">');
        let tabpanelul = $('<ul class="nav nav-tabs contextFrameTabs" role="tablist">');

        let tabpanels = $('<div class="tab-content">');

        ui.append(tabpanel);
        tabpanel.append(tabpanelul);
        tabpanel.append(tabpanels);

        tabpanelul.append(
            '<li role="presentation" class="active"><a href="#UIEDIT"  role="tab" data-toggle="tab">Parameters</a></li>',
        );
        let uiEdit = $('<div role="tabpanel"  style="height:100%" class="tabpaneltab tab-pane active" id="UIEDIT" >');
        tabpanels.append(uiEdit);

        tabpanelul.append(
            '<li role="presentation"><a href="#CODEEDIT"  role="tab" data-toggle="tab">Advanced</a></li>',
        );
        let codeEdit = $('<div role="tabpanel"  style="height:100%" class="tabpaneltab tab-pane" id="CODEEDIT" >');
        tabpanels.append(codeEdit);

        // ************************************************************************************
        // make a local copy of the current parameters
        // ************************************************************************************

        let updatedParams = ml.JSON.clone({ ...{}, ...params });

        // ************************************************************************************
        // add the UI to change the parameters. This is defined for each function's parameters
        // ************************************************************************************

        uiEdit.append(
            fct.editParams(updatedParams, (newParams) => (updatedParams = newParams)), // whenever the UI changes the parameters, update the local copy
        );

        // ************************************************************************************
        // add the JSON editor
        // ************************************************************************************

        const editHtml = codeEdit.html(""); // 'hack' as apparently jquery functions are not known here (unless it's running in the UI)
        if (editHtml.plainText) {
            editHtml.plainText({
                id: "",
                help: "&nbsp;",
                controlState: ControlState.DialogCreate,
                valueChanged: function () {
                    // ignore what the user types, until user ok's the dialog or changes the tab
                },
                isFolder: true,
                canEdit: true,
                fieldValue: JSON.stringify(updatedParams),
                parameter: {
                    code: "json",
                    height: dlg.height() - 60,
                    autoFormat: true,
                    showJSONFormat: true,
                    apiHelp: fct.getHelp(),
                },
            });
        }

        // ************************************************************************************
        // handle switching between tabs
        // ************************************************************************************

        if (codeEdit.getController) {
            const controller = codeEdit.getController();
            $('a[data-toggle="tab"]', ui)
                .on("show.bs.tab", async (e) => {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if ($(e.target).attr("href") == "#UIEDIT") {
                        // change to ui - only
                        let jsonStr = await controller.getValueAsync();
                        try {
                            updatedParams = JSON.parse(jsonStr);
                            // repaint UI
                            if (fct.editParams) {
                                uiEdit
                                    .html("")
                                    .append(fct.editParams(updatedParams, (newParams) => (updatedParams = newParams)));
                            }
                        } catch (ex) {
                            e.preventDefault();

                            ml.UI.showConfirm(
                                -1,
                                { title: "bad json", ok: "ignore changes", nok: "try to fix" },
                                () => {
                                    if (controller.setValue) {
                                        controller.setValue(JSON.stringify(updatedParams));
                                    }
                                },
                                () => {},
                            );
                        }
                    } else {
                        // change to code editor
                        if (controller.setValue) {
                            controller.setValue(JSON.stringify(updatedParams));
                        }
                    }
                })
                .on("shown.bs.tab", function (e) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if ($(e.target).attr("href") == "#UIEDIT") {
                    } else {
                        // update CodeMirror
                        $(".CodeMirror", dlg).height(ui.height() - 110);
                        if (controller.refresh) {
                            controller.refresh();
                        }
                    }
                });
        }

        // ************************************************************************************
        // show the dialog with both editors
        // ************************************************************************************

        let dialogTitle = "Options Editor";

        ml.UI.showDialog(
            dlg,
            dialogTitle,
            ui,
            $(document).width() * 0.9,
            app.itemForm.height() * 0.9,
            [
                // buttons
                {
                    text: "OK",
                    class: "btnDoIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: async function () {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if ($(".tabpaneltab.active", ui).attr("id") == "CODEEDIT") {
                            if (codeEdit.getController) {
                                const controller = codeEdit.getController();

                                let jsonStr = await controller.getValueAsync();
                                try {
                                    updatedParams = JSON.parse(jsonStr);
                                    onUpdate(updatedParams);
                                    dlg.dialog("close");
                                } catch (ex) {
                                    ml.UI.showConfirm(
                                        -1,
                                        {
                                            title: "bad json",
                                            ok: "ignore changes",
                                            nok: "try to fix",
                                        },
                                        () => {
                                            onUpdate(updatedParams);
                                            dlg.dialog("close");
                                        },
                                        () => {},
                                    );
                                }
                            }
                        } else {
                            onUpdate(updatedParams);
                            dlg.dialog("close");
                        }
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
            UIToolsConstants.Scroll.Vertical,
            false, // auto resize
            true, // maximize button
            () => {
                // close
                dlg.remove();
            },
            () => {
                // open

                tabpanels.height(ui.height() - 50);
                $(".CodeMirror", dlg).height(ui.height() - 110);
            },
            () => {
                // resize
                tabpanels.height(ui.height() - 50);
                $(".CodeMirror", dlg).height(ui.height() - 110);
            },
        );
        return true;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static editStyle(wrap: JQuery) {
        let code = wrap.attr("title");

        let dlg = $("<div>").appendTo($("body"));
        let ui = $("<div style='height:100%;width:100%'>");
        let val = {
            style: code
                ? code.replace(/'/g, '"')
                : 'color:$["renderFunction":"attribute", "attributeName":"value", "path":""]$;',
        };

        ml.UI.addTextInput(
            ui,
            "This allows you to define html style attributes",
            val,
            "style",
            () => {},
            () => {},
            false,
        );

        ml.UI.showDialog(
            dlg,
            "Edit Style",
            ui,
            $(document).width() * 0.9,
            200,
            [
                {
                    text: "OK",
                    class: "btnDoIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        wrap.attr("title", val.style.replace(/"/g, "'"));
                        dlg.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
            UIToolsConstants.Scroll.Vertical,
            true,
            true,
            () => {
                dlg.remove();
            },
            () => {},
            () => {},
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getItemFormatter(uid: string) {
        return uid && this.formatter && this.formatter.items ? this.formatter.items[uid] : null;
    }

    // add a function which sport items
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static addItemSorter(uid: string, sorter: IPrintSorter) {
        PrintProcessor.itemSorter[uid.toLowerCase()] = sorter;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getItemSorters() {
        return this.itemSorter;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getItemSorter(uid: string) {
        return uid ? <IPrintSorter>this.itemSorter[uid.toLowerCase()] : null;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getItemSorterDropdown() {
        let dd: IDropdownOption[] = [{ id: "", label: "don't sort" }];
        $.each(PrintProcessor.itemSorter, function (uid: string, sorter: IPrintSorter) {
            dd.push({ id: uid, label: sorter.getName() });
        });
        return dd;
    }

    // add a function which can iterate over items, labels or fields
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static addItemIterator(uid: string, iterator: IPrintIterator) {
        PrintProcessor.itemIterators[uid.toLowerCase()] = iterator;
    }

    static getItemIterator(uid: string, quiet?: boolean): IPrintItemIterator | null {
        const iterator = PrintProcessor.itemIterators[uid.toLowerCase()];
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (iterator != null) {
            return iterator as IPrintItemIterator;
        } else if (!quiet) {
            const errorMessage = `There is NO iterator with uid ${uid}`;
            console.error(errorMessage);
            return null;
        }
        return null;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getItemIteratorsDropdown(items: boolean, folders: boolean, allowNoIterator: boolean) {
        let dd: IDropdownOption[] = allowNoIterator ? [{ id: "", label: "No iterator" }] : [];
        $.each(PrintProcessor.itemIterators, function (uid: string, iterator: IPrintIterator) {
            if ((items && iterator.worksOnItem) || (folders && iterator.worksOnFolder)) {
                dd.push({ id: uid, label: iterator.getName() });
            }
        });
        return dd;
    }

    // add a function which can iterate over items, labels or fields
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static addLabelIterator(uid: string, iterator: IPrintIterator) {
        PrintProcessor.labelIterators[uid.toLowerCase()] = iterator;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getLabelIterator(uid: string) {
        return uid ? <IPrintLabelIterator>PrintProcessor.labelIterators[uid.toLowerCase()] : null;
    }

    // add a function which can iterate over items, labels or fields
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static addFieldIterator(uid: string, iterator: IPrintIterator) {
        PrintProcessor.fieldIterators[uid.toLowerCase()] = iterator;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getFieldIterator(uid: string) {
        return uid ? <IPrintFieldIterator>PrintProcessor.fieldIterators[uid.toLowerCase()] : null;
    }

    // add a function which converts some object info into a string
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static addFunction(uid: string, fctn: IPrintFunction) {
        PrintProcessor.functions[uid.toLowerCase()] = fctn;
    }
    static getFunction(uid: string): IPrintFunction | null {
        return uid ? PrintProcessor.functions[uid.toLowerCase()] : null;
    }
    static FIELD_FUNCTION_TYPE = "fieldtype";
    static FIELD_FUNCTION_PREFIX = "_field_";
    static getFieldFunctionId(uid: string): string {
        return `${PrintProcessor.FIELD_FUNCTION_PREFIX}${uid.toLowerCase()}`;
    }
    static getFieldFunction(uid: string): IPrintFunction | null {
        if (uid) {
            const id = this.getFieldFunctionId(uid);
            const fieldFunction = PrintProcessor.functions[id];
            if (fieldFunction && fieldFunction.getGroup() === PrintProcessor.FIELD_FUNCTION_TYPE) {
                return fieldFunction;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    // add a function which evaluates something about an item
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static addConditionFunction(uid: string, fctn: IConditionFunction) {
        PrintProcessor.conditions[uid.toLowerCase()] = fctn;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getConditionFunction(uid: string) {
        return uid ? PrintProcessor.conditions[uid.toLowerCase()] : null;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getItemConditionDropdown() {
        let dd: IDropdownOption[] = [{ id: "", label: "No condition" }];
        $.each(PrintProcessor.conditions, function (uid: string, condition: IConditionFunction) {
            if (condition.itemOrFolder) {
                dd.push({ id: uid, label: condition.getName() });
            }
        });
        return dd;
    }

    static getAllFunctions(): IPrintBaseFunctionMap {
        return ml.JSON.clone({
            ...PrintProcessor.functions,
            ...PrintProcessor.itemIterators,
            ...PrintProcessor.itemSorter,
            ...PrintProcessor.fieldIterators,
            ...PrintProcessor.labelIterators,
            ...PrintProcessor.conditions,
        });
    }

    static getAllIterators(): IPrintBaseFunctionMap {
        return ml.JSON.clone({ ...PrintProcessor.itemIterators });
    }

    // return json object or null
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getJsonConfig(config: string, mf: JQuery) {
        let node = $("settings setting[key='" + config + "']", mf);
        if (!node.length) {
            return null;
        }

        return this.getCdataAsJSON(node[0]);
    }

    // returns first cdata section as json (if it is json, null otherwise)
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getCdataAsJSON(node: Element) {
        if (!node) {
            return null;
        }
        if ($("params", node).length) {
            node = $("params", node)[0];
        } else if ($("param", node).length) {
            // dropdown
            node = $("param", node)[0];
        }
        if (!node.childNodes) {
            return null;
        }
        for (let idx = 0; idx < node.childNodes.length; idx++) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (node.childNodes[idx].nodeName == "#cdata-section") {
                const content = node.childNodes[idx].textContent ?? "";
                let comment = content
                    .replace(/^\[CDATA\[/, "")
                    .replace(/]]$/, "")
                    .trim();
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (comment && ["[", "{"].indexOf(comment.charAt(0)) != -1) {
                    try {
                        return JSON.parse(comment);
                    } catch (ex) {
                        // ignore sometimes it's rubbish
                    }
                }
            }
        }

        // no comment node
        return null;
    }

    // returns first cdata section as text
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getCdataAsText(node: Element, escapeHtmlEntities: boolean) {
        if (!node) {
            return "";
        }
        if (!node.childNodes) {
            return "";
        }
        for (let idx = 0; idx < node.childNodes.length; idx++) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (node.childNodes[idx].nodeName == "#cdata-section") {
                const content = node.childNodes[idx].textContent ?? "";
                //MATRIX-4981 If escaping is enabled then make sure html entities are escaped
                const innerContent = content
                    .replace(/^\[CDATA\[/, "")
                    .replace(/]]$/, "")
                    .trim();
                if (escapeHtmlEntities) {
                    return $("<div>").text(innerContent).html();
                } else {
                    return innerContent;
                }
            }
        }

        // no comment node
        return "";
    }

    // return info about user / group
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getUserName(user: string, mf: JQuery, first: boolean, last: boolean, login: boolean, email: boolean) {
        let userDef = $(`user_list user[login="${user}"]`, mf);
        if (userDef.length) {
            let parts: string[] = [];
            if (login) {
                parts.push(user);
            }
            if (first) {
                parts.push(userDef.attr("first"));
            }
            if (last) {
                parts.push(userDef.attr("last"));
            }
            if (email) {
                parts.push(userDef.attr("email"));
            }
            return `<span class="user_user">${parts.join(" ")}</span>`;
        }
        let groupDef = $(`group_list group[id="${user.replace("g_", "").replace("_g", "")}"]`, mf);
        if (groupDef.length) {
            return `<span class="user_group">${groupDef.attr("label")}</span>`;
        }
        return user;
    }
    // for the UI to show available options and editors
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getFieldAndLabelsIteratorsDropdown() {
        let dd: IDropdownOption[] = [];
        $.each(PrintProcessor.fieldIterators, function (uid: string, iterator: IPrintIterator) {
            dd.push({ id: uid, label: iterator.getName() });
        });
        $.each(PrintProcessor.labelIterators, function (uid: string, iterator: IPrintIterator) {
            dd.push({ id: uid, label: iterator.getName() });
        });
        return dd;
    }
}

class GlobalPrintConfig implements IPrintConfig {
    getPrintProcessor(): IPrintProcessor {
        return new PrintProcessor();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getFieldAndLabelsIteratorsDropdown() {
        return PrintProcessor.getFieldAndLabelsIteratorsDropdown();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getItemIteratorsDropdown(items: boolean, folders: boolean, allowNoIterator: boolean) {
        return PrintProcessor.getItemIteratorsDropdown(items, folders, allowNoIterator);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getItemConditionDropdown() {
        return PrintProcessor.getItemConditionDropdown();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showOptionsEditor(fctName: string, currentValue: string, onUpdate: (newValue: string) => void) {
        return PrintProcessor.showOptionsEditor(fctName, currentValue, onUpdate);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    editFunctionOptions(currentValue: string, onUpdate: (newOptions: string) => void) {
        return PrintProcessor.editFunctionOptions(currentValue, onUpdate);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    editStyle(wrap: JQuery) {
        return PrintProcessor.editStyle(wrap);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    functionHasOptions(functionUid: string) {
        return PrintProcessor.functionHasOptions(functionUid);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getFunctions(group: string) {
        return PrintProcessor.getFunctions(group);
    }
    getItemSorters(): IPrintSorterMap {
        return PrintProcessor.getItemSorters();
    }
    getAllFunctions(): IPrintBaseFunctionMap {
        return PrintProcessor.getAllFunctions();
    }
    getAllIterators(): IPrintBaseFunctionMap {
        return PrintProcessor.getAllIterators();
    }
}

if (typeof globalPrintConfig !== "undefined") {
    console.error("Global print config already defined, skip!");
} else {
    setGlobalPrintConfig(new GlobalPrintConfig());
}

class PrintUtilities {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static isFolder(path: string) {
        return path.indexOf("F-") === 0;
    }
}
