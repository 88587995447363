import { IDBParent } from "./DBCache";

import { ProjectStorage } from "../../client/ProjectStorage";
import { PushMessages, IItemWatched } from "./PushMessages";
import { ml } from "./../matrixlib";
import { NavigationPanel } from "../UI/MainTree/MainTree";
import { TokenControl } from "../UI/Parts/TokenControl";
import { userControls, ITokenConfig } from "../UI/Parts/UserControl";
import {
    IProjectGroups,
    INotificationConfig,
    notificationSetting,
    defaultNotificationConfig,
    IDeletedProjects,
    IMailConfig,
    mailConfigSetting,
} from "../../ProjectSettings";
import {
    XRProjectType,
    XRUserPermissionType,
    XRGetProject_StartupInfo_ListProjectAndSettings,
    XRTodoCount,
    XRTodo,
    XRMainAndBranch,
} from "../../RestResult";
import { NotificationList } from "../../client/plugins/Notifications";
import { ProjectStorageMobile } from "../../mobile/ProjectStorageMobile";
import { plugins } from "./PluginManager";
import { UIToolsConstants } from "../matrixlib/MatrixLibInterfaces";
import { mTasks } from "./Tasks";
import {
    IStringMap,
    globalMatrix,
    matrixApplicationUI,
    matrixSession,
    IAnyMap,
    app,
    restConnection,
    IItem,
    applyResponsiveView,
} from "../../globals";
import { ItemConfiguration } from "./ItemConfiguration";
import { mTM } from "./TestManager";
import { NotificationsBL } from "./NotificationsBL";

export type {
    IGetProjectResult,
    IGetProjectResultSetting,
    IGetProjectResultDateInfo,
    IGetProjectResultDateInfos,
    ICustomerSettingString,
    ICustomerSettingJSON,
    IPostLoginResult,
    IPostLoginResultUserDetail,
    ICompanyUISettings,
    ICompanyTiny,
    ICompanyTinyMenuMap,
    ICompanyTinyMenu,
};
export { MatrixSession };

interface IGetProjectResult {
    settings: IGetProjectResultSetting[];
    currentUser: string;
    customerAdmin: number;
    superAdmin: number;
    dateInfo: IGetProjectResultDateInfo;
    customerSettings: IGetProjectResultSetting[];
    project: XRProjectType[];
}

interface IGetProjectResultSetting {
    key: string;
    value: string;
}

interface IGetProjectResultDateInfo {
    timeformat: string;
    dateformat: string;
    timeZone: string;
    customerDateformat: string;
    customerTimeformat: string;
    customerTimezone: string;
    dateIso8601: string;
    timeUserFormat: string;
}

interface IGetProjectResultDateInfos {
    key: string;
    value: string;
}

interface ICustomerSettingString {
    [key: string]: string;
}

interface ICustomerSettingJSON {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    [key: string]: {};
}

interface IPostLoginResult {
    actualLogin: string;
    userId: number;
    userDetails: IPostLoginResultUserDetail;
    maxAge: number;
}

interface IPostLoginResultUserDetail {
    id: number;
    login: string;
    email: string;
    firstName: string;
    lastName: string;
    signatureImage: number;
    customerAdmin: number;
    passwordAgeInDays: number;
    badLogins: number;
    badLoginsBefore: number;
    superAdmin: number;
    userSettingsList: IGetProjectResultSetting[];
}

interface ICompanyUISettings {
    /** allow to add links to locked items */
    allowAddLinkToLocked?: boolean;
    /** if true the save button is always on the left */
    saveLeft?: boolean;
    /** if set to true, auto clean the input of text fields */
    purify?: boolean;
    /** editor setting */
    tiny?: ICompanyTiny; // tinymce settings
    /** always use new editor (also for old projects) */
    tinyAsDefault?: boolean; // true if tiny editor should be used as default
    /** true if user should be able to switch from editor to tiny per field */
    tinyUpgradeOption?: boolean;
    /** how many items to show in list view (after running searches, default 200) */
    maxHits?: number;
    /** bigger scale = sharper drawio images in PDF, default is 3 */
    drawIOScale?: boolean;
    /** @experimental: Enable the analytics dashboard */
    analyticsEnabled?: boolean;
    /** internal: url of draw io editor */
    drawioURL?: string; // can be used to overwrite the default drawio url (on their server)
    /** @experimental: if set to anything > 0 the fields in a form are rendered in a non blocking way if there are more than largeFormRender fields */
    largeFormRender?: number;
    /** @internal beta - do not auto select parents if single item is selected for DOC */
    preciseDocSelect?: boolean;
    /** @internal obsolete */
    legacyPrint?: boolean; // allows viewing print preview
    /** @internal obsolete */
    legacyUserDropdown?: number; // show normal user drop down in user select in table
    /** @internal obsolete */
    legacyKeepFolder?: boolean; // don't select a created folder after creation
    /** set the max items count when "... and expand tree" search modes are available. if not set defaults to 20000 */
    maxItemsForExpandSearch?: number;
}

interface ICompanyTiny {
    // tinymce settings
    /** true if browser context menu should be used as default */
    tinyHideMenu?: boolean;
    /** enable or disable editor plugins */
    plugins?: string[]; // list of plugins to use
    /*  plugins to add to (default) list  */
    extraPlugins?: string[];
    /** toolbar definition */
    toolbar?: string; // toolbar
    /** menubar definition  default: edit view insert format table matrix */
    menubar?: string; // menu bar
    /** menu entries can be used to change default menus or add details of new menu bar */
    menu?: ICompanyTinyMenuMap; // menu bar
    /** allows to overwrite any default setting (e.g. misc: { "table_toolbar": ""} )  to hide table toolbar */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    misc?: any;
    /** html entities to accept in text */
    extended_valid_elements?: string;
    /**  optional: formats in Paragraph menu (for docs) */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    block_formats_doc?: any;
    /** optional: rules for formats (for docs) */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    apply_formats_doc?: any;
    /** optional: formats in style menu (for docs) */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    style_formats_doc?: any;
    /** optional: formats in Paragraph menu (for items) */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    block_formats?: any;
    /** optional: rules for formats (for items)  */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    apply_formats?: any;
    /** optional: formats in style menu (for items) */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    style_formats?: any;
    /** elements which don't need content (e.g. a TD cell can be empty, needs to be the complete list) */
    short_ended_elements?: string;
    /** a custom css name/path */
    css?: string;
    /** if true it used dom purify to super clean the html */
    dompurify?: boolean;
    /** Obsolete */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    textpattern_patterns?: any[];
    /** define auto replacement in tiny editor - see https://www.tiny.cloud/docs/tinymce/6/content-behavior-options/#text_patterns */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    text_patterns?: any[];
}

interface ICompanyTinyMenuMap {
    [key: string]: ICompanyTinyMenu;
}

interface ICompanyTinyMenu {
    /** display name of menu */
    title: string;
    /** items to show */
    items: string;
}

class MatrixSession {
    private CurrentUser = "";
    private CurrentProject: string | null = null;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private CurrentComment: string;
    private customerAdmin: boolean = false;
    private superAdmin: boolean = false;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private dateInfo: IGetProjectResultDateInfo;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private customerSettingsString: ICustomerSettingString;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private customerSettingsJSON: ICustomerSettingJSON;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private ProjectList: XRProjectType[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private CommitTransaction: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private CommitTransactionComment: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private CommitTransactionCancelled: boolean;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private postConnect: any;
    public duringBrowserNavigation: boolean = false;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private userPermissions: XRUserPermissionType[];
    private licensedModules: string[];
    public lastManualComment: string = "";
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    public serverConfig: XRGetProject_StartupInfo_ListProjectAndSettings;

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    public pushMessages: PushMessages;
    private customParams: IStringMap = {};
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private branches: XRMainAndBranch[];

    quiet(): boolean {
        return typeof globalMatrix.jiraPlugin !== "undefined";
    }

    constructor() {
        console.log("Matrix session constructor");
        let that = this;

        window.addEventListener("message", this.receiveMessage, false);

        this.licensedModules = [];
        matrixApplicationUI.updateMainUI(true);

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        $.ajaxPrefilter(function (options: { beforeSend: Function }) {
            if (!options.beforeSend) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                options.beforeSend = function (xhr: JQueryXHR) {
                    let csrfToken = that.getCsrfCookie();
                    xhr.setRequestHeader("x-csrf", csrfToken);
                };
            }
        });
        this.tryReconnect().done(function () {
            // a session exists (e.g. F5 was pressed or a new url entered)
            // a list of project from this server is known and stored in ProjectList
            // the comment is the last one shown in the UI, should be reused in other tabs
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            that.CurrentComment = localStorage.getItem("CurrentComment");
            plugins.waitForAllPlugins().then(() => {
                that.updateUI(false);
            });
        });
    }

    public getCsrfCookie(): string {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let csrfToken = (<any>$).cookie("csrf"); // i put that in the call to get the latest cookie with each request (see B below)
        return csrfToken;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    startCommitTransaction() {
        this.CommitTransaction = true;
        this.CommitTransactionComment = "";
        this.CommitTransactionCancelled = false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    stopCommitTransaction() {
        this.CommitTransaction = false;
        this.CommitTransactionComment = "";
        this.CommitTransactionCancelled = false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getUser() {
        return this.CurrentUser;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setUser(login: string) {
        this.CurrentUser = login ? login.toLowerCase() : "";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private setDateInfo(di: IGetProjectResultDateInfo) {
        this.dateInfo = di;
        ml.UI.DateTime.initDateTimeSettings();
    }

    getDateInfo(): IGetProjectResultDateInfo {
        return this.dateInfo;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private setCustomerSettings(customerSettings: IGetProjectResultSetting[]) {
        let that = this;
        this.customerSettingsString = {};
        this.customerSettingsJSON = {};
        if (customerSettings) {
            $.each(customerSettings, function (idx: number, setting: IGetProjectResultSetting) {
                that.customerSettingsString[setting.key] = setting.value;
                if (setting.key !== "js_plugins" && setting.value && setting.value.indexOf("{") !== -1) {
                    // assume it a json
                    let val = ml.JSON.fromString(setting.value);
                    if (val.status === "ok") {
                        that.customerSettingsJSON[setting.key] = val.value;
                    }
                }
            });
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setCustomerSettingJSON(s: string, setting: {}) {
        this.customerSettingsJSON[s] = setting;
    }

    getCustomerSetting(s: string): string {
        return this.customerSettingsString[s];
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getCustomerSettingJSON(s: string, defaultValue?: {}): any {
        return this.customerSettingsJSON && this.customerSettingsJSON[s] ? this.customerSettingsJSON[s] : defaultValue;
    }

    getMailSettings(): IMailConfig {
        let projectMailConfig = globalMatrix.ItemConfig.getMailConfig();

        let serverMailConfig: IMailConfig = this.getCustomerSettingJSON(mailConfigSetting, { canned: {} });

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (projectMailConfig != undefined && projectMailConfig.canned != undefined) {
            for (let key in projectMailConfig.canned) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                serverMailConfig.canned[key] = projectMailConfig.canned[key];
            }
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (projectMailConfig != undefined && projectMailConfig.defaultCC != undefined) {
            serverMailConfig.defaultCC = projectMailConfig.defaultCC;
        }

        return serverMailConfig;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getUISettings(defaultValues?: {}): ICompanyUISettings {
        let ui: ICompanyUISettings = <ICompanyUISettings>matrixSession.getCustomerSettingJSON("ui", {});
        if (defaultValues) {
            $.each(defaultValues, function (key, defaultValue) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if ((<IAnyMap>ui)[key] == undefined) {
                    (<IAnyMap>ui)[key] = defaultValue;
                }
            });
        }
        return ui;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setUISetting(setting: string, value: any) {
        let that = this;

        let ui = <IAnyMap>matrixSession.getCustomerSettingJSON("ui", {});
        ui[setting] = value;

        app.setSettingCustomerJSON("ui", ui)
            .done(function () {
                console.log("New setting");
                console.log(ui);
                that.customerSettingsJSON[setting] = ui;
            })
            .fail(function () {
                console.log("Error applying setting");
            });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showUISettings() {
        let ui = matrixSession.getCustomerSettingJSON("ui", {});
        console.log(ui);
    }

    isEditor(): boolean {
        return globalMatrix.ItemConfig.hasWriteAccess(this.getUser());
    }

    isCustomerAdmin(): boolean {
        return this.customerAdmin;
    }

    isSuperAdmin(): boolean {
        return this.superAdmin;
    }

    // super admin or customer admin
    isAdmin(): boolean {
        return this.isCustomerAdmin() || this.isSuperAdmin();
    }

    getProject(): string | null {
        return this.CurrentProject;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setProject(projectId: string) {
        // this should (only be used by JIRA plugin)
        this.CurrentProject = projectId;
    }

    getCommentAsync(): JQueryDeferred<string> {
        let that = this;
        let res = $.Deferred();
        // get errors is comment needs ticked id
        let commentNeedsTicket: string[] = mTasks ? mTasks.evaluateTaskIds(matrixSession.getComment()) : [];

        if (this.CurrentComment && commentNeedsTicket.length === 0) {
            res.resolve(this.CurrentComment);
        } else if (this.CommitTransaction && this.CommitTransactionComment) {
            res.resolve(this.CommitTransactionComment);
        } else if (!app.commentRequired()) {
            res.resolve("no comment specified");
        } else {
            if (commentNeedsTicket.length > 0) {
                ml.UI.showError("You need a comment with a ticket id!", commentNeedsTicket.join(" "));
            }
            let oked = false;

            ml.UI.showDialogDes({
                container: $("#saveDlg"),
                minMaxHeight: -350,
                minMaxWidth: -500,
                buttons: [
                    {
                        text: "Save",
                        class: "btnDoIt",
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        click: function () {
                            oked = true;
                            let comment = $("#commentDlgTextSave").val().replace(/</g, "&lt;");
                            // remember for automation
                            that.lastManualComment = comment;

                            commentNeedsTicket = mTasks ? mTasks.evaluateTaskIds(comment) : [];

                            if (!comment && app.commentRequired()) {
                                ml.UI.showError("Comment required!", "");
                            } else if (commentNeedsTicket.length > 0) {
                                ml.UI.showError("You need a comment with a ticket id!", commentNeedsTicket.join(" "));
                            } else {
                                if ($("#idSessionComment").prop("checked")) {
                                    that.setComment($("#commentDlgTextSave").val().replace(/</g, "&lt;"));
                                }
                                if (that.CommitTransaction) {
                                    that.CommitTransactionComment = $("#commentDlgTextSave")
                                        .val()
                                        .replace(/</g, "&lt;");
                                }
                                $("#saveDlg").dialog("close");
                                res.resolve($("#commentDlgTextSave").val().replace(/</g, "&lt;"));
                            }
                        },
                    },
                    {
                        text: "Cancel",
                        class: "btnCancelIt",
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        click: function () {
                            $("#saveDlg").dialog("close");
                        },
                    },
                ],
                title: "Enter a change comment to save",
                onOpen: () => {
                    let dl = $("#saveDlg").closest(".ui-dialog").addClass("saveDlg");
                    dl[0].style.setProperty("z-index", "20000", "important");
                    $("#commentDlgTextSave").val(that.getComment().replace(/&lt;/g, "<")).focus();
                },
                onClose: () => {
                    if (!oked) {
                        if (that.CommitTransaction) {
                            that.CommitTransactionCancelled = true;
                        }
                        res.reject(that.CommitTransactionCancelled);
                    }
                },
                onResize: () => {
                    $("#saveDlg").css("width", "100%");
                },
            });
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return <any>res;
    }

    getComment(): string {
        let comment = this.CurrentComment ? this.CurrentComment : "";
        return comment;
    }

    private makeTeaser(comment?: string): string {
        if (!comment || comment.length < 30) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return comment; //.replace(/</g,"&lt;");
        }
        return comment.substring(0, 30 - 3) + "..."; //.replace(/</g,"&lt;");
    }

    getCommentTeaser(): string {
        return this.makeTeaser(this.getComment());
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setComment(comment?: string, internal?: boolean) {
        this.CurrentComment = comment ? comment : "";
        $("#comment").val(this.getComment().replace(/&lt;/g, "<"));

        if (!this.isConfigClient()) {
            localStorage.setItem("CurrentComment", this.CurrentComment);
        }

        if (comment && !internal) {
            let newComments: string[] = [];
            newComments.push(comment);
            let lastComments = this.getLastComments();
            for (let idx = 0; idx < lastComments.length && idx < 9; idx++) {
                let exists = false;
                for (let ni = 0; ni < newComments.length; ni++) {
                    exists = exists || newComments[ni] === lastComments[idx];
                }
                if (!exists) {
                    newComments.push(lastComments[idx]);
                }
            }
            globalMatrix.serverStorage.setItem("lastComments", JSON.stringify(newComments));
        }
        $("#comment").change();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    isGroup() {
        return (
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.licensedModules.indexOf("qms") != -1 ||
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.licensedModules.indexOf("acl") != -1 ||
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.licensedModules.indexOf("groups") != -1
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    isQMS() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.licensedModules.indexOf("qms") != -1;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    isCompose() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.licensedModules.indexOf("compose") != -1;
    }
    isMerge(): boolean {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.licensedModules.indexOf("merge") != -1;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    isReview() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.licensedModules.indexOf("review") != -1;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    isACL() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.licensedModules.indexOf("acl") != -1;
    }

    isQMSProject(projectArg?: string): boolean {
        const project = projectArg || this.getProject();

        for (let idx = 0; idx < this.ProjectList.length; idx++) {
            if (this.ProjectList[idx].shortLabel === project) {
                return this.ProjectList[idx].qmsProject;
            }
        }

        return false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    limitAdmin() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.licensedModules.indexOf("limitadmin") == -1;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    hasRisks() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.licensedModules.indexOf("risk") != -1;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    hasVariants() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.licensedModules.indexOf("labels") != -1;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    hasDocs() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.licensedModules.indexOf("doc") != -1;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    hasAgileSync() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.licensedModules.indexOf("agilerocks") != -1;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private setModules(startupInfo: XRGetProject_StartupInfo_ListProjectAndSettings) {
        this.licensedModules = startupInfo.license.options;
    }

    private getLastComments(): string[] {
        let lastComments = globalMatrix.serverStorage.getItem("lastComments");
        if (!lastComments) {
            return [];
        } else {
            return JSON.parse(lastComments);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    tryReconnect(): JQueryDeferred<{}> {
        let that = this;

        let res = $.Deferred();

        this.updateSettings()
            .done(function () {
                res.resolve();
            })
            .fail(function () {
                plugins.initServerSettings();
                that.requestLogin(res);
            });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    signInAfterTimeout(): JQueryDeferred<{}> {
        let that = this;
        let res = $.Deferred();
        this.requestLogin(res);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    triggerLoginWithDialog() {
        let that = this;
        app.canNavigateAwayAsync()
            .done(function () {
                let res = $.Deferred();
                res.done(function () {
                    if (that.isConfigClient()) {
                        app.postLogin(that.getUser());
                    } else {
                        that.updateUI();
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.loadProject(null, location.href);
                    }
                });
                that.requestLogin(res);
            })
            .fail(function () {
                ml.UI.showError("You have unsaved changes.", "Save or cancel before signing out.");
            });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    changePassword() {
        userControls.editUserDetails(
            "useredit",
            this.getUser(),
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            function () {},
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            function () {},
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getProjectList(readOrWriteOnly: boolean) {
        return readOrWriteOnly
            ? this.ProjectList.filter(function (project) {
                  // TODO: MATRIX-7555: lint errors should be fixed for next line
                  // eslint-disable-next-line
                  // TODO: MATRIX-7555: lint errors should be fixed for next line
                  // eslint-disable-next-line
                  return project.accessType == "write" || project.accessType == "read";
              })
            : this.ProjectList;
    }

    // return true if a user has access to a given project
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    canSeeProject(project: string) {
        let rw = this.getProjectList(true);
        for (let idx = 0; idx < rw.length; idx++) {
            if (rw[idx].shortLabel === project) {
                return true;
            }
        }
        return false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private changeToken() {
        let dlg = $("<div>").appendTo($("body"));
        let ui = $("<div>").addClass("token-dialog");
        const currentToken = $("<div>").appendTo(ui);
        TokenControl.showUserTokens(currentToken, matrixSession.getUser());

        $("<br>").appendTo(ui);
        const newTokenDisplay = $("<div id='newTokenDisplay' class='token-dialog__new-token-display'></div>").appendTo(
            ui,
        );
        ml.UI.showDialog(
            dlg,
            "Access Tokens",
            ui,
            $(document).width() * 0.9,
            $(document).height() * 0.9,
            [
                {
                    text: "OK",
                    class: "btnDoIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
            UIToolsConstants.Scroll.Vertical,
            true,
            true,
            () => {
                dlg.remove();
                $("#newTokenDisplay").remove();
            },
            () => {},
            () => {},
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static NewTokenDisplay(tokenValue: string) {
        const newTokenSection = $("#newTokenDisplay");
        newTokenSection.html("");

        const newTokenMessage = $("<div>")
            .text("Make sure to copy your token now. You will not be able to see it again!")
            .addClass("token-dialog__new-token-display-message")
            .appendTo(newTokenSection);

        $("<div>").text(tokenValue).addClass("token-dialog__new-token-display-value").appendTo(newTokenSection);

        const copyTokenButton = $("<button>").text("Copy").addClass("btn btn-default").appendTo(newTokenSection);

        let copiedMessageDisplayed = false;

        copyTokenButton.click(function () {
            if (!copiedMessageDisplayed) {
                navigator.clipboard.writeText(tokenValue).then(() => {
                    copiedMessageDisplayed = true;
                    const tokenIsCopiedMessage = $("<div>")
                        .text("Copied!")
                        .addClass("token-dialog__new-token-display-copied-message")
                        .appendTo(newTokenSection);
                    setTimeout(() => {
                        tokenIsCopiedMessage.remove();
                        copiedMessageDisplayed = false;
                    }, 1000);
                });
            }
        });
        newTokenSection.addClass("token-dialog__new-token-display-section");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public setProjectColor(projectShort: string, color: string) {
        let projectColors = <IStringMap>this.getCustomerSettingJSON("projectColors", {});
        projectColors[projectShort] = color;
        this.setCustomerSettingJSON("projectColors", projectColors);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getProjectColor(projectShort: string) {
        let projectColors = <IStringMap>this.getCustomerSettingJSON("projectColors", {});
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (projectColors[projectShort] != undefined) {
            return projectColors[projectShort];
        } else {
            return ml.UI.calculateColorFrom(projectShort).color;
        }
    }

    public getImgFromProject(pRef: string): string {
        let that = this;
        let color = that.getProjectColor(pRef);
        let img = `<div class="project-icon" style="background:${color};"> <div></div></div>`;
        return img;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private createProjectSelectLink(pRef: string, pName: string, branchParents: IStringMap, lastParent: string[]) {
        let that = this;
        let li = $("<li class='dropdown-item'>");
        let img = that.getImgFromProject(pRef);
        let link = $("<span class='mainmenu'>" + pRef + " - " + pName + "</span>");
        li.click(function (e: JQueryEventObject) {
            $(".navbar-collapse.in").removeClass("in").addClass("collapse"); // for phones, hide menu
            let project = $(e.delegateTarget).data("projectid");
            app.canNavigateAwayAsync()
                .done(function () {
                    that.loadProject(project);
                })
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                .fail(function () {});
        }).data("projectid", pRef);

        if (branchParents[pRef]) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (lastParent.length && lastParent[0] == branchParents[pRef]) {
                li.addClass("_branched");
            }
        } else {
            lastParent.splice(0, 0, pRef);
        }
        li.append($(img));
        li.append(link);
        return li;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public amIAllowedUser(limitedTo: string[]) {
        let all = limitedTo ? limitedTo : [];
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (all.length == 0) {
            // no limits
            return true;
        }

        if (this.isSuperAdmin()) {
            return true;
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (all.indexOf(this.getUser()) != -1) {
            // I am a explicitly named user!
            return true;
        }

        let userCanDo = false;
        for (let userGroup of globalMatrix.ItemConfig.getUserGroups()) {
            if (
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                all.indexOf(ml.UI.SelectUserOrGroup.getGroupId(userGroup)) != -1 &&
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                userGroup.membership.map((member) => member.login).indexOf(this.getUser()) != -1
            ) {
                userCanDo = true;
            }
        }

        return userCanDo;
    }

    public updateUI(afterTimeout?: boolean): void {
        const project = this.getProject();

        if (this.quiet()) {
            if (project) {
                plugins.initProject(project);
                mTM.InitializeProject();
            }

            return;
        }
        //  set logged in user
        this.showUserMenu();
        applyResponsiveView();

        if (afterTimeout) {
            // we do not want to update the item tree / selected item otherwise
            // we loose the last edits
            if (project && globalMatrix.ItemConfig.isConfigured()) {
                plugins.initProject(project);
                mTM.InitializeProject();
            }
            return;
        }
        let height = Math.max(200, $("#main").height());
        // set project list
        $("#idProjectList")
            .html("")
            .css("max-height", height + "px");

        ml.UI.setEnabled($(".bottomNavHelp"), true);

        // admin / config client, hand over control
        if (globalMatrix.matrixProduct === "Admin" || this.isConfigClient()) {
            app.postLogin(this.getUser());
            //By default save save in on the left
            if (matrixSession.getUISettings({ saveLeft: true }).saveLeft) {
                $("#btnCancel").insertBefore("#btnSave");
            } else {
                $("#btnCancel").insertAfter($("#btnSave"));
            }
            return;
        }

        // MATRIX-1892 - swap save / cancel button in UI
        if (matrixSession.getUISettings({ saveLeft: true }).saveLeft) {
            //By default save save in on the left
            $("#btnCancel").insertBefore("#btnSave");
        } else {
            $("#btnCancel").insertAfter("#btnSave");
        }

        // retrieve and prepare project groups and start render tree
        let project_groups = <IProjectGroups>matrixSession.getCustomerSettingJSON("project_groups");

        let branchParents: Record<string, string> = {};

        for (let branch_info of this.branches ? this.branches : []) {
            branchParents[branch_info.branch] = branch_info.mainline;
        }

        // in the top: add the QMS project or QMS projects group (if there's one)
        let groupCount = this.addLiveQMSProjects();

        // get the define project groups
        let groups = project_groups && project_groups.groups ? project_groups.groups : [];

        // build a menu with project groups
        if (groupCount || groups.length) {
            $("#idProjectList").css("overflow-y", "inherit");
            groups.push({ name: "All Projects", projects: [] });

            $.each(groups, (groupIndex, group) => {
                let all = $('<ul class="dropdown-menu dropdown-menu-sub">');
                let count = 0;
                let projects = this.getProjectList(true);
                let lastParent: string[] = [];
                for (let idx = 0; idx < projects.length; idx++) {
                    let pRef = projects[idx].shortLabel;
                    let pName = projects[idx].label;
                    if (pRef !== "EMPTY" || this.isSuperAdmin()) {
                        if (!group.projects || group.projects.length === 0 || group.projects.indexOf(pRef) !== -1) {
                            all.append(this.createProjectSelectLink(pRef, pName, branchParents, lastParent));
                            count++;
                        }
                    }
                }
                if (count > 0) {
                    $("#idProjectList").append(
                        $('<li class="dropdown-submenu">')
                            .append('<a href="javascript:void(0)">' + group.name + "</a>")
                            .append(all),
                    );

                    groupCount++;
                }

                // calculate dynamically if there will some issues with it in the future
                const itemHeight = 34;

                all.css("max-height", $("#main").height() - groupIndex * itemHeight - itemHeight);

                window.addEventListener("resize", () => {
                    all.css("max-height", $("#main").height() - groupIndex * itemHeight - itemHeight);
                });
            });
        }

        // there's actually only one group (that means max one qms project)
        // so we want to render a flat list of projects
        if (groupCount < 2) {
            $("#idProjectList").css("overflow-y", "auto").html("");
            this.addLiveQMSProjects();
            let projects = this.getProjectList(true);
            let lastParent: string[] = [];
            for (let idx = 0; idx < projects.length; idx++) {
                let pRef = projects[idx].shortLabel;
                let pName = projects[idx].label;
                if (pRef !== "EMPTY" || this.isSuperAdmin()) {
                    $("#idProjectList").append(this.createProjectSelectLink(pRef, pName, branchParents, lastParent));
                }
            }
        }
        // Let hooks plugin in there
        let menusFromPlugin = plugins.getProjectMenuItems();
        if (menusFromPlugin && menusFromPlugin.length > 0) {
            $("#idProjectList").append($("<li class='divider'></li>"));

            for (let menu of menusFromPlugin) {
                let img = $('<i class="fal fa-external-link class menu-icon"  > </i>');
                if (menu.icon) {
                    img = $('<i class="fal ' + menu.icon + ' class menu-icon"  > </i>');
                }
                let link = $("<span class='mainmenu'>" + menu.title + "</span>");
                let li = $("<li class='dropdown-item'>");
                li.click(function (e: JQueryEventObject) {
                    menu.action();
                });
                li.append(img);
                li.append(link);
                $("#idProjectList").append(li);
            }
        }
        // default off
        let cbAutoCommit = $("#idAutoCommit");
        let commentTb = $("#comment");

        cbAutoCommit.prop("checked", localStorage.getItem("idAutoCommit") === "true").change(function () {
            localStorage.setItem("idAutoCommit", cbAutoCommit.prop("checked"));
        });

        this.updateCommentCheckboxBoxVisibility();
        commentTb.change((evt) => {
            this.updateCommentCheckboxBoxVisibility();
        });

        if (globalMatrix.matrixProduct === "Launch") {
            // nothing to do
        } else {
            this.loadProject(null, location.href, false);
        }
    }

    // if there is one QMS project just add a link... if there's multiple add a menu and 'increase' the sub menu counter by one
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public addLiveQMSProjects() {
        let projects = matrixSession.getProjectList(false).filter((project) => {
            return project.qmsProject;
        });
        if (projects.length > 1) {
            let all = $('<ul class="dropdown-menu dropdown-menu-sub"> </ul>');
            projects.forEach((project) => {
                let that = this;
                let color = that.getProjectColor(project.shortLabel);
                let img = $('<i class="fal fa-external-link class menu-icon" style="color:' + color + '" > </i>');
                let link = $("<span class='mainmenu'>" + project.shortLabel + " - " + project.label + "</span>");

                let li = $("<li class='dropdown-item'>");
                li.click(function (e: JQueryEventObject) {
                    $(".navbar-collapse.in").removeClass("in").addClass("collapse"); // for phones, hide menu
                    window.open(globalMatrix.matrixBaseUrl + "/pub/" + project.shortLabel);
                });

                li.append(img);
                li.append(link);
                all.append(li);
            });
            $("#idProjectList").append(
                $('<li class="dropdown-submenu"></li>')
                    .append(
                        $(
                            '<a href="javascript:void(0)">' +
                                "My QMS " +
                                ' <i class="fal fa-external-link my-qms-icon" style="" "></i></a> ',
                        ),
                    )
                    .append(all),
            );
            return 1;
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (projects.length == 1) {
            let color = this.getProjectColor(projects[0].shortLabel);
            let img = $('<i class="fal fa-external-link menu-icon" style="color:' + color + '" > </i>');
            let link = $("<span class='mainmenu'>" + projects[0].shortLabel + " - " + projects[0].label + "</span>");
            let li = $("<li class='dropdown-item _qms'>").append(img);
            link.insertAfter(img);
            li.click(function (e: JQueryEventObject) {
                $(".navbar-collapse.in").removeClass("in").addClass("collapse"); // for phones, hide menu
                window.open(globalMatrix.matrixBaseUrl + "/pub/" + projects[0].shortLabel);
            });
            $("#idProjectList").append(li);
            return 0;
        }

        return 0;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public updateCommentCheckboxBoxVisibility(): any {
        let commentTb = $("#comment");
        let cbAutoCommit = $(".autoSave");

        if (commentTb.val() === "") {
            cbAutoCommit.hide();
        } else {
            cbAutoCommit.show();
        }
    }

    loadProject(projectId: string | null, projectURL?: string, setAsProjectUrl?: boolean): void {
        // if called from menu the projectURL is null
        // if called after page load, the projectID is null
        // if called after Sign in menu , the projectID is null

        if (projectURL === null) {
            $("#filterDialog").remove();
        }

        matrixApplicationUI.updateMainUI(true);

        //Add browser notification support
        let notificationConfig = <INotificationConfig>(
            matrixSession.getCustomerSettingJSON(notificationSetting, defaultNotificationConfig)
        );

        if (
            notificationConfig.browserNotificationDisabled === undefined ||
            notificationConfig.browserNotificationDisabled === false
        ) {
            if (window.Notification && window.Notification.permission !== "granted") {
                window.Notification.requestPermission();
            }
        }

        resetHighlightLinks();

        this.CurrentProject = projectId;
        // in case projectURL is known, it or the local storage should be used
        if (projectURL) {
            this.CurrentProject = this.getProjectFromUrlOrStorage(projectURL);
        }

        if (this.CurrentProject) {
            // make sure it still exists
            let stillOK = false;
            let projects = this.getProjectList(true);
            for (let idx = 0; idx < projects.length; idx++) {
                if (this.CurrentProject === projects[idx].shortLabel) {
                    stillOK = true;
                    $("#sidebar").show();
                    $("#main").show();
                    $("#explainOuter").hide();
                }
            }
            if (!stillOK) {
                this.showProjectSelectMessage();
                $("#idProject").html(`<div id="projectNameTitleContainer" class="btn btn-xs">
                   <div id="projectNameTitle">
                       <div class="project-icon" style=""> <div></div></div>
                       <span> Select project</span>
                       <span class="caret">
                   </span></div>
               </div>`);
                matrixApplicationUI.destroyOldControls();
                NavigationPanel.destroy();
                ml.UI.showError(
                    "Project does not exist!",
                    "The project '" +
                        this.CurrentProject +
                        "' either does not exist or you have no read/write access to it.",
                );
                setTimeout(function () {
                    ml.UI.showSuccess("Select a another project");
                }, 3000);
                return;
            }
        } else {
            this.showProjectSelectMessage();

            $("#idProject").html(`<div id="projectNameTitleContainer" class="btn btn-xs">
                   <div id="projectNameTitle">
                       <div class="project-icon" > <div></div></div>
                       <span> Select project</span>
                       <span class="caret">
                   </span></div>
               </div>`);
            matrixApplicationUI.destroyOldControls();
            NavigationPanel.destroy();

            ml.UI.showSuccess("select a project");
            return;
        }

        if (!projectURL) {
            projectURL = app.createItemUrl();
            history.pushState(null, "", projectURL);
        } else if (setAsProjectUrl) {
            history.pushState(null, "", globalMatrix.matrixBaseUrl + "/" + projectURL);
        }

        let projectName = matrixSession.getProject();

        if (projectName) {
            const foundProjects = matrixSession.getProjectList(false).filter((o) => {
                o.shortLabel === projectName;
            });

            const img = this.getImgFromProject(projectName);
            const projectNameTitleCtrl = `<div id="projectNameTitleContainer" class="btn btn-xs" >
                   <div id="projectNameTitle">
                       ${img}
                       <span> ${projectName}</span>
                       <span class="caret"/>
                   </div>
               </div>`;

            const color = matrixSession.getProjectColor(projectName);

            if (!app.isConfigApp()) {
                $("header").css("border-top", "solid 5px " + color);
            }

            $("#tooltip_panel").css("border", "solid 5px " + color);

            $("#idProject").html(projectNameTitleCtrl);
        }

        $("#scrollBarStyle").remove();

        globalMatrix.serverStorage.setItem("SessionLastProject", this.CurrentProject);
        if (typeof globalMatrix.mobileApp !== "undefined") {
            globalMatrix.projectStorage = new ProjectStorageMobile(this.CurrentProject);
        } else {
            globalMatrix.projectStorage = new ProjectStorage(
                this.CurrentProject,
                app.getVersion(),
                globalMatrix.matrixBaseUrl,
                DOMPurify,
            );
        }
        // if url specifies it use the item if not use last item from storage for project
        let item = this.getItemFromUrlOrStorage(projectURL);
        globalMatrix.projectStorage.setItem("SessionLastItem", item);

        app.loadProject(this.CurrentProject, item);

        // auto run search

        function runSearch(): void {
            $("#projectTree input[name=search]").val(search);
            $("#projectTree .mrqlSearchIcon>i").click();
        }

        let search = ml.URL.parseUrl(projectURL).params.search;

        if (search) {
            app.waitForMainTree(runSearch);
        }

        // Store params for later use
        this.customParams = ml.URL.parseUrl(projectURL).params;

        // Automatically switch to item when mobileLayout
        let mobileLayout = localStorage.getItem("mobileLayout");

        if (mobileLayout && mobileLayout !== "") {
            localStorage.setItem("mobileLayout", "1");
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (window as any).applyResponsiveView();
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    oAuthOnly() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return globalMatrix.mxOauth == "mandatory";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showProjectSelectMessage(forceIntro?: boolean) {
        $("#sidebar").hide();
        $("#main").hide();
        $("#contextframe").addClass("hidden");
        $("#contextframesizer").addClass("hidden");
        $("#explainOuter").show();
        $("#explain").html("");

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        function showimg(img: string) {
            $(".explainimg").html("<img src='" + globalMatrix.matrixBaseUrl + "/img/" + img + "'/>");
            $("#imgModal").modal();
        }

        $("#explain").height($("#explainOuter").height());
        let projectsRW = this.getProjectList(true);

        if (!projectsRW || projectsRW.length === 0) {
            $("#explain").append("<div class='explainh1'>Welcome to Matrix Requirements</div>");

            // Get qms viewer only
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            let projectListQms = matrixSession.getProjectList(false).filter((item) => item.accessType == "qmsviewer");
            if (projectListQms.length > 0) {
                const ul = $("<ul class='explainMore'/>");
                $("#explain").append("<div class='explainh2'>You can access  to these projects as a QMS viewer</div>");
                $("#explain").append(ul);
                projectListQms.forEach((item) => {
                    ul.append(
                        "<li class='explainmore'><a href='" +
                            globalMatrix.matrixBaseUrl +
                            "/pub/" +
                            item.shortLabel +
                            "'>" +
                            item.shortLabel +
                            " </a> - " +
                            item.label +
                            "</li>",
                    );
                });
            } else {
                $("#explain").append(
                    "<div class='explainh2'>You do not have access to any projects. Please contact your Matrix Requirements administrator.</div>",
                );
            }
        } else if (globalMatrix.matrixExpress || forceIntro) {
            let projects = projectsRW.map(function (ple: XRProjectType) {
                return ple.shortLabel;
            });

            $("#explain").append("<div class='explainh1'>Welcome to Matrix Requirements</div>");
            $("#explain").append(
                "<div class='explainh2'>Below are a few examples for project configurations. They have different item categories and traceability rules. Your actual project structure will most likely be different - Ask us, we help you to set it up quickly!</div>",
            );
            //$("#explain").append( "<div class='explainh3'>The item categories define what kind of items you can store in the database. The traceability rules define which up and downstream links between the categories can or must exist. This can be changed in the admin client, but before getting into that ask us </div>" );

            $("#explain").append("<div class='explainh1'>MatrixALM - Design History Files and more</div>");
            if (
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                projects.indexOf("SIMPLEST") == -1 &&
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                projects.indexOf("DEFAULT") == -1 &&
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                projects.indexOf("MREQPROJ") == -1
            ) {
                $("#explain").append(
                    "<div class='explainh2'>The ALM module is not enabled - let us know if you want to get it!</div>",
                );
            } else {
                const ul = $("<ul>");
                $("#explain").append($("<div class='explainh2'>").append(ul));
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (projects.indexOf("SIMPLEST") != -1) {
                    ul.append(
                        $(
                            "<li class='explainmore'>SIMPLEST: covers requirements, specification, tests (<span class='explainImg' data-src='simplest.png' >show</span> - <span class='explainProject' data-src='SIMPLEST' >open project</span>)</li>",
                        ),
                    );
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (projects.indexOf("DEFAULT") != -1) {
                    ul.append(
                        $(
                            "<li class='explainmore'>DEFAULT: covers risks, requirements, specification, tests and use cases (<span class='explainImg' data-src='default.png' >show</span> - <span class='explainProject' data-src='DEFAULT' >open project</span>)</li>",
                        ),
                    );
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (projects.indexOf("MREQPROJ") != -1) {
                    ul.append(
                        $(
                            "<li class='explainmore'>MREQPROJ: covers risks, user and system requirements, specification, tests and use cases (<span class='explainImg' data-src='mreq.png' >show</span> - <span class='explainProject' data-src='MREQPROJ' >open project</span>)</li>",
                        ),
                    );
                }
                $("#explain").append(
                    "<div class='explainh3'>If you need more projects, different types of categories, input fields, traceability rules or risk formulas please do not hesitate to contact us.</div>",
                );
            }

            $("#explain").append("<div class='explainh1'>MatrixQMS - Quality System, CAPAs and more</div>");
            if (
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                projects.indexOf("QMS") == -1 &&
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                projects.indexOf("CAPA") == -1 &&
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                projects.indexOf("QMS_FILE") == -1 &&
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                projects.indexOf("HR_FILE") == -1
            ) {
                $("#explain").append(
                    "<div class='explainh2'>The QMS module is not enabled - let us know if you want to get it!</div>",
                );
            } else {
                const ul = $("<ul>");
                $("#explain").append($("<div class='explainh2'>").append(ul));
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (projects.indexOf("QMS") != -1) {
                    ul.append(
                        $(
                            "<li class='explainmore'>QMS: all your standards, procedures, work instructions, procedural risks, etc ... (<span class='explainProject' data-src='QMS' >open project</span>)</li>",
                        ),
                    );
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (projects.indexOf("CAPA") != -1) {
                    ul.append(
                        $(
                            "<li class='explainmore'>CAPA: Corrective and preventive action forms (<span class='explainProject' data-src='CAPA' >open project</span>)</li>",
                        ),
                    );
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (projects.indexOf("QMS_FILE") != -1) {
                    ul.append(
                        $(
                            "<li class='explainmore'>QMS_FILE: the place for QMS records (<span class='explainProject' data-src='QMS_FILE' >open project</span>)</li>",
                        ),
                    );
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (projects.indexOf("HR_FILE") != -1) {
                    ul.append(
                        $(
                            "<li class='explainmore'>HR_FILE: human resource records (<span class='explainProject' data-src='HR_FILE' >open project</span>)</li>",
                        ),
                    );
                }
            }

            let modal = `
                <div class="modal fade  modal-lg" id="imgModal" tabindex="-1" role="dialog">
                    <div class="modal-dialog   explain-modal" role="document">´
                <div class="modal-content  explain-modal">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 class="modal-title" id="myModalLabel">Configuration Preview</h4>
                </div>
                <div class="modal-body">
                    <div class='explainimg'></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
            </div>`;
            $("#explain").append(modal);

            $(".explainImg").click(function (event: JQueryEventObject) {
                showimg($(event.delegateTarget).data("src"));
            });
            $(".explainProject").click(function (event: JQueryEventObject) {
                window.open(globalMatrix.matrixBaseUrl + "/" + $(event.delegateTarget).data("src"), "_blank");
            });
        } else {
            $("#explain").append("<div class='explainh1'>Welcome to Matrix Requirements</div>");
            $("#explain").append(
                "<div class='explainh2' style='text-align: center;'>Please select a project from the project menu in the top.</div>",
            );
            $("#comment").prop("disabled", false);
        }
    }

    private filterProjects(projectList: XRProjectType[]): XRProjectType[] {
        let dp = <IDeletedProjects>matrixSession.getCustomerSettingJSON("deleted_projects");
        if (!dp) {
            return projectList;
        }
        // filter project by deleted ones
        return projectList.filter(function (pli) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return dp.deleted.indexOf(pli.shortLabel) == -1;
        });
    }

    private getItemFromUrlOrStorage(projectURL: string): string {
        if (!projectURL) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return null;
        }

        let parsedUrl = ml.URL.parseUrl(projectURL);

        if (parsedUrl.item) {
            return parsedUrl.item;
        }

        return globalMatrix.projectStorage.getItem("SessionLastItem");
    }

    private getProjectFromUrlOrStorage(projectURL: string): string {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let project: string = null;

        let parsedUrl = ml.URL.parseUrl(projectURL);

        if (parsedUrl.project) {
            project = parsedUrl.project;
        } else {
            project = globalMatrix.serverStorage.getItem("SessionLastProject");
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (project == "#") {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            project = null;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            window.history.pushState(null, null, window.location.href.replace("#", ""));
        }

        return project;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    browserNavigation() {
        let item = this.getItemFromUrlOrStorage(location.href);
        let project = this.getProjectFromUrlOrStorage(location.href);

        if (this.CurrentProject !== project) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.loadProject(null, location.href);
            return;
        }
        this.duringBrowserNavigation = true;
        app.treeSelectionChangeAsync(item);
        this.duringBrowserNavigation = false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    signOut(requestAdminRights: boolean) {
        let that = this;
        app.canNavigateAwayAsync()
            .done(function () {
                $("#idProject").html("");
                matrixApplicationUI.destroyOldControls();
                $("#user").html("");
                $("#mainUserMenu").html("");
                NavigationPanel.destroy();
                ml.UI.setEnabled($(".bottomNavHelp"), false);

                restConnection.postServer("user/" + that.getUser() + "/logout").done(function () {
                    let res = $.Deferred();
                    that.signOutCleanUp();
                    res.done(function () {
                        that.updateUI();
                    });
                    that.requestLogin(res, requestAdminRights);
                });
            })
            .fail(function () {
                ml.UI.showError("You have unsaved changes.", "Save or cancel before signing out.");
            });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    editComment() {
        let that = this;

        let lastCommentsSession = this.getLastComments();
        // dropdown select last comment
        $("#recentCommentChange").find("li").remove();
        for (let idx = 0; idx < lastCommentsSession.length; idx++) {
            let option = $('<li><a data-target="#">' + this.makeTeaser(lastCommentsSession[idx]) + "</a></li>")
                .click(function (e: JQueryEventObject) {
                    $("#commentDlgText").val($(e.delegateTarget).data("full").replace(/&lt;/g, "<"));
                })
                .data("full", lastCommentsSession[idx]);

            $("#recentCommentChange").append(option);
        }

        $("#commentDlgText").val(this.getComment().replace(/&lt;/g, "<"));

        ml.UI.showDialogDes({
            container: $("#commentDlg"),
            minMaxHeight: -400,
            minMaxWidth: -550,
            buttons: [
                {
                    text: "Save",
                    class: "btnDoIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        that.setComment($("#commentDlgText").val().replace(/</g, "&lt;"));
                        $("#commentDlg").dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        $("#commentDlg").dialog("close");
                    },
                },
            ],
            title: "Enter comment used when saving",
            onOpen: () => {
                window.setTimeout(function () {
                    $("#commentDlgText").select();
                }, 100);
            },
            onClose: () => {},
            onResize: () => {
                $("#commentDlg").css("width", "100%");
            },
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public showLoginWindow() {
        const loginFrame = $("#loginFrame");
        const loginBox = loginFrame.contents().find(".login-full-box");
        if (loginBox.length === 0) {
            // Not loaded yet
            console.info("LOGIN: Not ready yet, try again");
            window.setTimeout(() => this.showLoginWindow(), 100);
            return;
        }

        if (loginFrame.is(":visible") && loginBox.is(":visible")) {
            // nothing to do
            console.info("LOGIN: Already visible, nothing to do");
            return;
        }

        loginFrame.show();
        loginBox.show();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public hideLoginWindow() {
        const loginFrame = $("#loginFrame");
        const loginBox = loginFrame.contents().find(".login-full-box");
        if (loginBox.length === 0) {
            // Not loaded yet
            console.info("LOGIN: Not ready yet, try again");
            window.setTimeout(() => this.hideLoginWindow(), 100);
            return;
        }

        if (loginFrame.is(":hidden") && loginBox.is(":hidden")) {
            // nothing to do
            console.info("LOGIN: Already hidden, nothing to do");
            return;
        }

        loginFrame.hide();
        loginBox.hide();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private requestLogin(res: any, requestAdminRights?: boolean) {
        let that = this;
        const loginFrame = $("#loginFrame");
        if (!loginFrame.length) {
            return;
        } // for unit tests

        this.showLoginWindow();

        that.postConnect = res;

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        (<any>loginFrame[0]).contentWindow.postMessage("activateUser", window.location.origin);
        if (requestAdminRights) {
            window.setTimeout(function () {
                // give the frame some time to load...
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                (<any>loginFrame[0]).contentWindow.postMessage("admin", window.location.origin);
            }, 1000);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private receiveMessage(event: any) {
        let that = this;

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (event.origin == window.location.origin && event.data == "login") {
            // login successful
            $("#loginFrame").hide();

            // prepare next login ()
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (<any>$("#loginFrame")[0]).contentWindow.location.reload();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            matrixSession.setComment(localStorage.getItem(globalMatrix.matrixBaseUrl + "_useComment"));
            // if there is a specific point to continue.. go for it
            matrixSession
                .updateSettings()
                .done(function () {
                    matrixSession.showUserMenu();
                    // retrieved some settings, go back to start
                    if (matrixSession.postConnect) {
                        matrixSession.postConnect.resolve();
                    }
                })
                .fail(function () {
                    // this should not really happen (since we just logged in...)
                    matrixSession.requestLogin(matrixSession.postConnect);
                });
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (event.origin == window.location.origin && event.data == "login-failed") {
            ml.UI.showError("SSO Login failed", "Unable to login via external authentication system.");
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (event.origin == window.location.origin && event.data == "loginDetails") {
            // test if something should be announced
            if (Number(localStorage.getItem("badLogins")) > 0) {
                ml.UI.showSuccess(
                    "Before this successful login " +
                        localStorage.getItem("badLogins") +
                        " unsuccessful login attempts have been done.",
                    4000,
                );
            }
            let expire = Number(localStorage.getItem("expiration"));
            if (expire > -1) {
                ml.UI.showError(
                    "Password will expire soon!",
                    "Your password will expire in " + expire + " days.",
                    4000,
                );
            }
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelpButton() {
        return `
            <ul id="mainHelpBtnMenu" class="dropdown-menu dropdown-menu-main usermenu" role="menu">
                <li><a id="" href="https://urlshort.matrixreq.com/d25/faq" rel="noopener noreferrer" target="_blank">FAQ</a></li>
                <li><a id="" href="https://urlshort.matrixreq.com/d25/manual" rel="noopener noreferrer" target="_blank">User guide</a></li>
                <li><a id="" href="https://urlshort.matrixreq.com/d25/admin" rel="noopener noreferrer" target="_blank">Administrative guide</a></li>
                <li class="divider"></li>
                <li><a id="" href="https://urlshort.matrixreq.com/contact" rel="noopener noreferrer" target="_blank">Contact us</a></li>
                <li><a id="" href="https://urlshort.matrixreq.com/helpdesk" rel="noopener noreferrer" target="_blank">Service desk</a></li>
                <li><a id="" href="https://urlshort.matrixreq.com/d25/community" rel="noopener noreferrer" target="_blank">Community</a></li>
            </ul>
        `;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showUserMenu() {
        let that = this;
        let info = that.getUser();
        // display current user name
        $("#user").html("");
        $("#user").append(ml.UI.getAvatar(info, 32));
        //Fill user menu when clicked. This allows race conditions to be avoided with plugins not being loaded yet.
        $("#user").click(() => {
            // fill user menu
            $("#mainUserMenu").html("");

            // add sign out
            $(
                '<li id="signoutcommand"><button class="btn-plain dropdown-menu-main-button">Sign out <i class="fal fa-sign-out-alt" style="float: right;font-size: 17px;"></i> </button></li>',
            )
                .appendTo($("#mainUserMenu"))
                .click(function () {
                    that.signOut(false);
                });

            if (!that.isConfigClient()) {
                $('<li class="divider" style=""></li>').appendTo($("#mainUserMenu"));
                // add change user profile
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (globalMatrix.matrixBaseUrl.indexOf("demo.matrixreq.com") === -1 || that.getUser() != "demo") {
                    $(
                        '<li class="changepasswordmenu"><button id="myprofile" class="btn-plain dropdown-menu-main-button">My Profile<i class="fal fa-user" style="float: right;font-size: 17px;"></i></button></li>',
                    )
                        .appendTo($("#mainUserMenu"))
                        .click(function () {
                            that.changePassword();
                        });
                }

                let settingsToken = <ITokenConfig>this.getCustomerSettingJSON("settingsToken");
                if (
                    settingsToken &&
                    settingsToken.enabled &&
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    (settingsToken.users.length == 0 || settingsToken.users.indexOf(matrixSession.getUser()) != -1)
                ) {
                    $(
                        '<li class="changetoke"><button id="mytoken" class="btn-plain dropdown-menu-main-button">My Tokens<i class="fal fa-key" style="float: right;font-size: 17px;"></i></button></li>',
                    )
                        .appendTo($("#mainUserMenu"))
                        .click(function () {
                            that.changeToken();
                        });
                }

                let userMenuItems = plugins.getUserMenuItems();
                if (userMenuItems.length > 0) {
                    $('<li class="divider" style=""></li>').appendTo($("#mainUserMenu"));
                    for (let menu of userMenuItems) {
                        let icon = '<i class="fal fa-columns" style="float: right;font-size: 17px;"></i>';
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if (menu.icon != undefined) {
                            icon = '<i class="' + menu.icon + '" style="float: right;font-size: 17px;"></i>';
                        }
                        let msg =
                            "<button class='btn-plain dropdown-menu-main-button'>" + menu.title + icon + "</button> ";
                        $('<li style="position: relative;">' + msg + "</li>")
                            .appendTo($("#mainUserMenu"))
                            .click(function (event: JQueryEventObject) {
                                menu.action();
                            });
                    }
                }
                // add admin client
                if (this.isCustomerAdmin() || this.isSuperAdmin()) {
                    $('<li class="divider" style=""></li>').appendTo($("#mainUserMenu"));
                    $(
                        '<li class="adminsettings"><a id="serveradmin" href="' +
                            globalMatrix.matrixBaseUrl +
                            "/adminConfig" +
                            '" target="_blank">Server Administration <i class="fal fa-cog" style="float: right;font-size: 17px;"></i></a></li>',
                    ).appendTo($("#mainUserMenu"));
                    $("#serveradmin").click((evt) => {
                        if (globalMatrix.globalShiftDown && matrixSession.getProject()) {
                            window.open(
                                globalMatrix.matrixBaseUrl + "/adminConfig/" + matrixSession.getProject(),
                                "_blank",
                            );
                            evt.preventDefault();
                            return false;
                        }
                    });
                }
            } else {
                let userMenuItems = plugins.getConfigUserMenuItems();
                if (userMenuItems.length > 0) {
                    $('<li class="divider" style=""></li>').appendTo($("#mainUserMenu"));
                    for (let menu of userMenuItems) {
                        let icon = '<i class="fal fa-columns" style="float: right;font-size: 17px;"></i>';
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if (menu.icon != undefined) {
                            icon = '<i class="' + menu.icon + '" style="float: right;font-size: 17px;"></i>';
                        }
                        let msg =
                            "<button class='btn-plain dropdown-menu-main-button'>" + menu.title + icon + "</button> ";
                        $('<li style="position: relative;">' + msg + "</li>")
                            .appendTo($("#mainUserMenu"))
                            .click(function (event: JQueryEventObject) {
                                menu.action();
                            });
                    }
                }
            }
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initPushMessaging(): JQueryDeferred<{}> {
        let that = this;

        let res = $.Deferred();

        if (this.pushMessages) {
            res.resolve();
            this.pushMessages.newConnection();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return res;
        }
        this.pushMessages = new PushMessages();

        this.pushMessages
            .newConnection()
            .always(function () {
                res.resolve();
                // handle todos
                that.pushMessages.onTodoChanged(async function () {
                    let projects = matrixSession.getProjectList(true).map(function (p) {
                        return p.shortLabel;
                    });
                    let result = await NotificationsBL.NoticationCache.update(projects);
                    NotificationList.updateUI(result);
                });
                // handle item changes
                that.pushMessages.onItemUpdated(function (changed) {
                    // also update the tree, rename the item if needed
                    if (app.getItemTitle(changed.item)) {
                        NavigationPanel.update(<IItem>{ id: changed.item, title: changed.title });
                        that.updateWatchItemVersion(changed.item, changed.version);
                    }
                });

                // handle new items
                that.pushMessages.onItemCreated(function (changed) {
                    let itemJson: IItem = {
                        id: changed.item,
                        title: changed.title,
                        type: ml.Item.parseRef(changed.item).type,
                    };
                    if (ml.Item.parseRef(changed.item).isFolder) {
                        itemJson["children"] = [];
                    }
                    let newItem: IDBParent = {
                        parent: changed.parent,
                        position: 10000,
                        item: <IItem>itemJson,
                    };

                    window.setTimeout(function () {
                        // wait a bit more than a typical round trip to server before the update
                        // maybe the item was already created by some other action (e.g. it's a XTC execution the user did himself)
                        if (!app.getItemTitle(changed.item)) {
                            app.insertInTree(newItem);
                            app.updateCache(newItem);
                        }
                    }, 500);
                });

                // handle deleting items
                that.pushMessages.onItemDeleted(function (changed) {
                    // we need to wait a bit, cause if I deleted it, it will go away by itself
                    window.setTimeout(function () {
                        if (app.getItemTitle(changed.item)) {
                            // otherwise I deleted it...
                            NavigationPanel.remove(changed.item);
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            if (app.getCurrentItemId() == changed.item) {
                                ml.UI.showAck(-1, "Someone just deleted the item you are looking at: " + changed.item);
                            }
                        }
                    }, 1000);
                });

                // show people watching items
                that.pushMessages.onItemWatched(function (watchInfo) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (watchInfo.item == app.getCurrentItemId()) {
                        if (watchInfo.editor && !watchInfo.editor.thisSocket) {
                            // apparently someone else is editing the item...
                            // make it readonly, maybe update it, indicate editor
                            app.someOneElseIsChanging(watchInfo);
                        } else if (watchInfo.editor && watchInfo.editor.thisSocket) {
                            // i got notified that I am editing an item, I don't care
                            // unless someone else modified the item in the mean time
                            if (
                                that.lastWatchInfo && // I watch something (not very first item load)
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                that.lastWatchInfo.item == app.getCurrentItemId() && // and what I watch is displayed (should always be the case)
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                watchInfo.item == that.lastWatchInfo.item && // and the news is still for that item
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                watchInfo.version != that.lastWatchInfo.version && // but the news is that the version is not what I displayed
                                that.lastWatchInfo.version > 0 // this means it's not currently saving
                            ) {
                                // I got disconnected while editing (and someone else made a change before I logged on again)

                                app.someOneElseWasChanging(watchInfo);
                            } else {
                                // all good  make sure list with editors / viewers is up-to-date
                                app.updateItemViewers(watchInfo);
                            }
                        } else {
                            // no (more) editor
                            if (that.lastWatchInfo && that.lastWatchInfo.editor) {
                                // someone was editing before
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                if (that.lastWatchInfo.item == app.getCurrentItemId()) {
                                    // someone (else) stopped editing (e.g. by cancelling)
                                    if (that.lastWatchInfo.editor.thisSocket) {
                                        // I stopped editing
                                        if (app.needsSave()) {
                                            // not actually true MATRIX-3211 - we just ignore this message
                                            ml.Logger.log(
                                                "warning",
                                                "received message as if I stopped editing, but not true, so I ignore it!",
                                            );
                                        }
                                        // in any case update viewers
                                        app.updateItemViewers(watchInfo);
                                    } else {
                                        app.someOneElseStoppedEditing(watchInfo, that.lastWatchInfo);
                                    }
                                } else {
                                    // someone else stopped editing another item (e.g. by cancelling)
                                    // so we don't care about stopping something but we want to show the editors of this item
                                    app.updateItemViewers(watchInfo);
                                }
                            } else {
                                // nobody edits and edited before
                                app.updateItemViewers(watchInfo);
                            }
                        }
                        // remember last watchers
                        if (
                            !(
                                that.lastWatchInfo &&
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                that.lastWatchInfo.item == watchInfo.item &&
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                Math.abs(that.lastWatchInfo.version) == watchInfo.version
                            )
                        ) {
                            // handle race conditions: keep negative version from saving
                            that.lastWatchInfo = watchInfo;
                        }
                    }
                });
            })
            .fail(function () {
                res.reject();
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lastWatchInfo: IItemWatched;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateWatchItemVersion(itemId: string, newVersion: number) {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.lastWatchInfo && this.lastWatchInfo.item == itemId) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (newVersion == -1) {
                newVersion = -Math.abs(this.lastWatchInfo.version);
            }
            this.lastWatchInfo.version = newVersion;
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    isConfigClient() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return globalMatrix.matrixProduct == "Configuration Client";
    }

    private updateSettings(): JQueryDeferred<void> {
        let res: JQueryDeferred<void> = $.Deferred();

        restConnection
            .getServer("?exclude=allUsers,allTodos", true)
            .done((response) => {
                const result = response as XRGetProject_StartupInfo_ListProjectAndSettings;
                const sanitizedResult = this.sanitizeSettings(result);

                if (sanitizedResult.license.maxReadWrite < sanitizedResult.readWriteUsers.length) {
                    ml.UI.showError("License Issue", "There are not enough write licenses.");
                }

                this.setModules(sanitizedResult);
                this.serverConfig = structuredClone(sanitizedResult);
                plugins.initServerSettings(sanitizedResult);

                this.setUser(sanitizedResult.currentUser);
                this.setDateInfo(sanitizedResult.dateInfo);
                this.setCustomerSettings(sanitizedResult.customerSettings);

                let projects = sanitizedResult.project.map(function (pi) {
                    return pi.shortLabel;
                });

                NotificationsBL.NoticationCache.setNotificationCounts(
                    sanitizedResult.todoCounts.filter(function (todoCount) {
                        return projects.indexOf(todoCount.projectShort) !== -1;
                    }),
                );
                // a session is still active
                this.ProjectList = this.filterProjects(sanitizedResult.project);

                this.customerAdmin = sanitizedResult.customerAdmin === 1;
                this.superAdmin = sanitizedResult.superAdmin === 1;

                if (this.isConfigClient() && !this.isAdmin() && !this.isSuperAdmin()) {
                    this.signOut(true);
                } else if (this.isConfigClient()) {
                    res.resolve();
                } else {
                    this.initPushMessaging().always(function () {
                        // in any case, software will run without websockets
                        res.resolve();
                    });
                }

                this.branches = sanitizedResult.branches;
            })
            .fail(function () {
                res.reject();
            });

        return res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getBranches(mainline: string, branch: string) {
        if (!this.branches) {
            return [];
        }
        return this.branches.filter(
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (info) => (!mainline || mainline == info.mainline) && (!branch || branch == info.branch),
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private signOutCleanUp() {
        app.mainTreeLoaded = false;
        this.customerAdmin = false;
        this.superAdmin = false;
        this.CurrentUser = "";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getCustomParams() {
        return this.customParams;
    }

    private sanitizeSettings(
        data: XRGetProject_StartupInfo_ListProjectAndSettings,
    ): XRGetProject_StartupInfo_ListProjectAndSettings {
        const clonedData = structuredClone(data);

        clonedData.project = clonedData.project.map((project) => ({
            ...project,
            shortLabel: DOMPurify.sanitize(project.shortLabel),
            label: DOMPurify.sanitize(project.label),
        }));

        return clonedData;
    }
}
